import React, { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { ModalManager } from "react-dynamic-modal";
import Info from "./Info";

class InfoContainer extends Component {
	constructor(props) {
		super();

		this.saveInfo = this.saveInfo.bind(this);
	}

	async saveInfo(form) {
		const { dispatch } = this.props;

		this.props.showProcessingModal("Saving application...");

		let post = {
			loan_id: form.loan_id,
			business: form.business_name,
			first: form.owner_1_first,
			last: form.owner_1_last,
			mobile: form.owner_1_mobile,
			email: form.owner_1_email,
			amount: form.loan_amount,
			terms: form.loan_terms,
			payment: form.loan_payment,
			equipment_description: form.equipment_description,
			loan_type: this.props.loan_type,
		};

		const response = await this.props.callApi("info/save", post);

		if (!response.error) {
			dispatch(change("application", "business_id", response.business_id));
			dispatch(change("application", "owner_1_id", response.owner_id));
			dispatch(change("application", "loan_id", response.loan_id));

			this.props.nextRoute();
		}

		ModalManager.close();
	}

	render() {
		return <Info onSubmit={this.saveInfo} {...this.props} />;
	}
}

const appSelector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		loan_type: appSelector(state, "loan_type") || "eq",
		config: state.config,
	};
};

export default connect(mapStateToProps)(InfoContainer);
