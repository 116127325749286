import styles from "scss/styles.scss";

const Theme = {
  options: {
    default: {
      primary: styles.primary,
      secondary: styles.secondary,
      name: "default"
    },
    lendingTree: {
      primary: styles.lendingTreePrimary,
      secondary: styles.secondary,
      name: "lendingtree"
    }
  },

  load(setTheme, match = null, currentTheme = null) {
    const option = match && match.params ? match.params.option : null;

    if (option) {
      switch (option) {
        case "lending-tree":
          setTheme(this.options.lendingTree);
          break;

        default:
          setTheme(this.options.default);
          break;
      }
    } else if (!currentTheme.name) {
      setTheme(this.options.default);
    }

    // need to reset theme on home page
    if (match.path === "/") {
      setTheme(this.options.default);
    }
  }
};

export default Theme;
