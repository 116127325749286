import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Process from "./Process";
import Decline from "./Decline";
import Frozen from "./Frozen";
import "Decision.scss";

let Decision = (props) => {
  switch (props.match.params.property) {
		case "process":
			return <Process {...props} />;
		case "decline":
			return <Decline {...props} />;
		case "frozen":
			return <Frozen {...props} />;
		
		default:
			return null;
	}
};

const selector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		fico: selector(state, "owner_1_fico"),
		theme: state.theme,
	};
};

export default connect(mapStateToProps)(withRouter(Decision));
