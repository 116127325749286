import React from "react";
import { Field } from "redux-form";
import { customInput } from "_fields/inputs";
import { phone } from "_fields/normalizers";
import { required, phoneNumber } from "_fields/validation";
import PhoneIcon from "_icons/PhoneIcon";

const Phone = props => {
  return (
    <React.Fragment>
      <PhoneIcon fill={props.theme.primary} />
      <h3>Business phone number</h3>

      <Field
        name="business_phone"
        component={customInput}
        type="tel"
        normalize={phone}
        validate={[required, phoneNumber]}
        autoFocus
      />
    </React.Fragment>
  );
};

export default Phone;
