import React from "react";

const ChevronIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="21"
      viewBox="0 0 13 21"
    >
      <path
        fill={props.fill}
        d="M11.5,20.49a.8.8,0,0,1-.54-.21l-11-10,11-10a.79.79,0,0,1,1.13.05A.79.79,0,0,1,12,1.39L2.37,10.24,12,19.1a.79.79,0,0,1,.05,1.13A.8.8,0,0,1,11.5,20.49Z"
      />
    </svg>
  );
};

export default ChevronIcon;
