import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import { estPaymentByApr } from "@utils/paymentCalculations";
import Amount from "./Amount.jsx";

class AmountContainer extends Component {
	constructor(props) {
		super();

		this.saveAmount = this.saveAmount.bind(this);
		this.updatePayment = this.updatePayment.bind(this);
	}

	saveAmount(form) {
		this.props.setSaving(true);
		this.updatePayment();
		this.props.nextRoute();
		this.props.setSaving(false);
	}

	updatePayment() {
		let { loanAmount, loanTerms, loanType, config } = this.props;

		const paymentAmount = estPaymentByApr(
			loanAmount,
			loanTerms,
			config[loanType].rate
		);

		this.props.dispatch(change("application", "loan_payment", paymentAmount));
	}

	render() {
		return (
			<Amount
				onSubmit={this.saveAmount}
				updatePayment={this.updatePayment}
				{...this.props}
			/>
		);
	}
}

const selector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		loanAmount: selector(state, "loan_amount"),
		loanType: selector(state, "loan_type") || "eq",
		loanTerms: selector(state, "loan_terms"),
		equipment: selector(state, "equipment_description"),
		first: selector(state, "owner_1_first"),
		config: state.config,
	};
};

export default connect(mapStateToProps)(withRouter(AmountContainer));
