import React from "react";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import loading from "loading.gif";
import "Alert.scss";

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const styles = (theme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.dark,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: 5,
	},
	message: {
		display: "flex",
		alignItems: "center",
	},
});

let Alert = (props) => {
	const {
		classes,
		className,
		message,
		onClose,
		variant,
		showProcessing,
		showFeedbackLink,
		...other
	} = props;
	const Icon = variantIcon[variant];
	let loaderClass = classNames({
		"alert-loader": true,
		hide: !showProcessing,
	});

	const feedback = showFeedbackLink ? (
		<a className="fb-link" onClick={() => Sentry.showReportDialog()}>
			Report feedback
		</a>
	) : null;

	return (
		<SnackbarContent
			className={classNames(classes[variant], className, "alert-message")}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={classNames(classes.icon, classes.iconVariant)} />
					{message}
					<img
						src={loading}
						className={loaderClass}
						width="16"
						height="16"
						alt=""
					/>{" "}
					{feedback}
				</span>
			}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					className={classes.close}
					onClick={onClose}
				>
					<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	);
};

Alert.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

export default withStyles(styles)(Alert);
