import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Field } from "redux-form";
import { words } from "_fields/normalizers";
import { customInput } from "_fields/inputs";
import { required } from "_fields/validation";
import OwnershipIcon from "_icons/OwnershipIcon";
import Indicator from "../Indicator";

let Ownership = props => {
  const { ownerIdx, theme, first, last } = props;

  return (
    <React.Fragment>
      <OwnershipIcon fill={theme.primary} />
      <h3>Title & Ownership <br/>for {first} {last}</h3>

      <Indicator ownerIdx={ownerIdx} />

      <Field
        name={`owner_${ownerIdx}_title`}
        component={customInput}
        type="text"
        normalize={words}
        validate={[required]}
        labelText="Job title"
        autoFocus
      />

      <Field
        name={`owner_${ownerIdx}_ownership`}
        component={customInput}
        type="tel"
        validate={[required]}
        labelText="Percentage of ownership"
      />
    </React.Fragment>
  );
};

const selector = formValueSelector("application");

const mapStateToProps = (state, ownProps) => {
  return {
    first: selector(state, `owner_${ownProps.ownerIdx}_first`),
    last: selector(state, `owner_${ownProps.ownerIdx}_last`)
  };
};

Ownership = connect(mapStateToProps)(Ownership);

export default Ownership;
