import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Field } from "redux-form";
import { format } from "date-fns";
import "date-fns";
import { datePicker } from "_fields/inputs";
import IdentityIcon from "_icons/IdentityIcon";
import Indicator from "../Indicator";
import SsnInput from "../SsnInput";
import { withStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { required } from "_fields/validation";

const styles = {
	paper: {
		padding: "10px",
	},
	popover: {
		pointerEvents: "none",
	},
	typography: {
		padding: "25px",
		width: "320px",
		fontSize: "12px",
		textAlign: "justify",
		position: "relative",
	},
};

class Identity extends PureComponent {
	state = {
		selectedDate: this.props.dob
			? format(new Date(`${this.props.dob}T12:00:00`), "MM/dd/yyyy")
			: null,
		open: false,
		anchorEl: null,
	};

	fieldKey = `owner_${this.props.ownerIdx}`;

	handleDateChange = (date) => {
		// prevent attempting to format invalid or null dates (allows manual editing)
		if (date == "Invalid Date" || date === null) {
			this.props.change(`${this.fieldKey}_dob`, null);
			return;
		}
		const dbDate = format(date, "yyyy-MM-dd");
		const pickerDate = format(date, "MM/dd/yyyy");

		this.setState({ selectedDate: pickerDate });

		this.props.change(`${this.fieldKey}_dob`, dbDate);
	};

	handleClick = (event, popoverId) => {
		this.setState({
			openedPopoverId: popoverId,
			anchorEl: event.target,
		});
	};

	handleClose = () => {
		this.setState({
			openedPopoverId: null,
			anchorEl: null,
		});
	};

	render() {
		const {
			ownerIdx,
			theme,
			change,
			first,
			last,
			classes,
		} = this.props;

    const { anchorEl, openedPopoverId } = this.state;
    
		return (
			<React.Fragment>
				<IdentityIcon fill={theme.primary} />

				<h3>Identity Confirmation <br/>for {first} {last}</h3>

				<Indicator ownerIdx={ownerIdx} />

				<p>Please provide the following:</p>

				<label className="label-above">Date of Birth</label>

				<Field
					component={datePicker}
					dateValue={this.state.selectedDate}
					handleDateChange={this.handleDateChange}
					className="input-100"
					type="tel"
					name={`owner_${ownerIdx}_dob`}
					validate={[required]}
					tabIndex="-1"
				/>

				<label className="label-above">Social Security Number</label>
				<SsnInput change={change} ownerIdx={ownerIdx} />
				<div
					className="disclosure-title"
					onClick={(e) => this.handleClick(e, "disclosure-popover")}
				>
					Why do you need this?
				</div>
				<Popover
					id="disclosure-popover"
					open={openedPopoverId === "disclosure-popover"}
					anchorEl={anchorEl}
					onClose={this.handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<Typography className={classes.typography}>
						We use your social security number to validate your information and
						check your credit. This will NOT affect your credit score. Your
						information is transmitted securely.
						<CloseIcon className="close-popover" onClick={this.handleClose} />
					</Typography>
				</Popover>
			</React.Fragment>
		);
	}
}

const selector = formValueSelector("application");

const mapStateToProps = (state, ownProps) => {
	return {
		dob: selector(state, `owner_${ownProps.ownerIdx}_dob`),
		ssn: selector(state, `owner_${ownProps.ownerIdx}_ssn`),
		first: selector(state, `owner_${ownProps.ownerIdx}_first`),
		last: selector(state, `owner_${ownProps.ownerIdx}_last`),
	};
};

Identity = connect(mapStateToProps)(Identity);

export default withStyles(styles)(Identity);
