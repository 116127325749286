import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Redirect } from "react-router-dom";
import { muiInput } from "_fields/inputs";
import { required, emailAddress } from "_fields/validation";
import ContinueButton from "_shared/Continue/Button";
import OwnershipIcon from "_icons/OwnershipIcon";
class SigninEmail extends Component {
  render() {
    const { theme, handleSubmit, invalid, saving, user } = this.props;

    if (user.loggedIn) {
      return <Redirect to="/user/dashboard" />;
    } else {
      return (
        <div className="app-section">
          <form autoComplete="off" onSubmit={handleSubmit}>
            <OwnershipIcon fill={theme.primary} />
            <h3>Sign in</h3>
            <p>Sign in to your account with your email.</p>
            <Field
              name="email"
              component={muiInput}
              type="email"
              label="Email"
              className="input-100"
              validate={[required, emailAddress]}
              autocomplete={false}
            />
            <ContinueButton showLock invalid={invalid} saving={saving} />
          </form>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

SigninEmail = reduxForm({
  form: "login",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SigninEmail);

export default connect(mapStateToProps)(SigninEmail);
