import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, user, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			user.loggedIn ? (
				<Component {...props} {...rest} />
			) : (
				<Redirect
					to={{ pathname: "/user/signin", state: { from: props.location } }}
				/>
			)
		}
	/>
);

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(PrivateRoute);
