import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Business from "./Business";

class BusinessContainer extends Component {
	constructor(props) {
		super();

		this.saveBusiness = this.saveBusiness.bind(this);
	}

	async saveBusiness(form) {
		const { property } = this.props.match.params;

		// do not send home based property to sugar, only used to skip address
		if (property === "based") {
			this.setSaving(false);
			this.props.nextRoute();
			return;
		}

		const post = {
			property: property,
			[property]: form[`business_${property}`],
		};

		// remove any non-numeric data from years input
		if (property === "years") {
			post.years = post.years.replace(/\D/g, "");
		}

		if (property === "address") {
			post.address = {
				street: form.business_street,
				suite: form.business_suite,
				city: form.business_city,
				state: form.business_state,
				zip: form.business_zip,
			};
		}

		const response = await this.props.callApi(`business/${property}`, post);

		if (!response.error) {
			this.props.nextRoute();
		}
	}

	render() {
		return <Business onSubmit={this.saveBusiness} {...this.props} />;
	}
}

const mapStateToProps = (state) => {
	return {
		theme: state.theme,
	};
};

export default connect(mapStateToProps)(withRouter(BusinessContainer));
