import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import AddressIcon from "_icons/AddressIcon";
import Address from "_shared/Address/Address";

let AddressBusiness = props => {
	const address = {
		street: props.street,
		suite: props.suite,
		city: props.city,
		state: props.state,
		zip: props.zip
	};

	return (
		<React.Fragment>
			<AddressIcon fill={props.theme.primary} />
			<h3>Enter business address</h3>

			<Address address={address} change={props.change} theme={props.theme} />
		</React.Fragment>
	);
};

const selector = formValueSelector("application");

const mapStateToProps = (state, ownProps) => {
	return {
		street: selector(state, "business_street"),
		suite: selector(state, "business_suite"),
		city: selector(state, "business_city"),
		state: selector(state, "business_state"),
		zip: selector(state, "business_zip")
	};
};

AddressBusiness = connect(mapStateToProps)(AddressBusiness);

export default AddressBusiness;
