import React, { Component } from "react";
import { Field } from "redux-form";
import { customInput } from "_fields/inputs";
import { required, ssn } from "_fields/validation";
import { normalizeSsn, maskSsn } from "_fields/normalizers";
import "SsnInput.scss";

class SsnInput extends Component {
	handleChange = (event) => {
		this.props.change(
			`owner_${this.props.ownerIdx}_ssn_masked`,
			maskSsn(event.target.value, true)
		);
	};

	render() {
		const { ownerIdx } = this.props;

		return (
			<div className="ssn-input-wrap group">
				<Field
					component={customInput}
					className="masked-ssn input-100"
					type="tel"
					name={`owner_${ownerIdx}_ssn_masked`}
					tabIndex="-1"
				/>

				<Field
					component={customInput}
					className="actual-ssn input-100"
					type="tel"
					name={`owner_${ownerIdx}_ssn`}
					onChange={this.handleChange}
					normalize={normalizeSsn}
					validate={[required, ssn]}
				/>
			</div>
		);
	}
}

export default SsnInput;
