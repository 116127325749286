import React, { Component } from "react";
import classNames from "classnames";

class SkipStep extends Component {
	constructor(props) {
		super(props);

		this.skipStep = this.skipStep.bind(this);
	}

	skipStep() {
		if (this.props.invalid) {
			this.props.nextRoute();
		}
	}

	render() {
		const { invalid, skipText } = this.props;

		let linkClass = classNames("faux-link", { muted: invalid ? false : true });

		if (skipText) {
			return (
				<div className={linkClass} onClick={this.skipStep}>
					{skipText}
				</div>
			);
		} else {
			return null;
		}
	}
}

export default SkipStep;
