import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import AddressIcon from "_icons/AddressIcon";
import Indicator from "../Indicator";
import Address from "_shared/Address/Address";

let AddressOwner = props => {
	const { ownerIdx, theme, first, last } = props;
	
	const address = {
		street: props.street,
		suite: props.suite,
		city: props.city,
		state: props.state,
		zip: props.zip
	};

	return (
		<React.Fragment>
			<AddressIcon fill={theme.primary} />
			<h3>Home Address for <br/>{first} {last}</h3>

			<Indicator ownerIdx={ownerIdx} />

			<Address
				address={address}
				change={props.change}
				theme={theme}
				ownerIdx={ownerIdx}
			/>
		</React.Fragment>
	);
};

const selector = formValueSelector("application");

const mapStateToProps = (state, ownProps) => {
	return {
		street: selector(state, `owner_${ownProps.ownerIdx}_street`),
		suite: selector(state, `owner_${ownProps.ownerIdx}_suite`),
		city: selector(state, `owner_${ownProps.ownerIdx}_city`),
		state: selector(state, `owner_${ownProps.ownerIdx}_state`),
		zip: selector(state, `owner_${ownProps.ownerIdx}_zip`),
		first: selector(state, `owner_${ownProps.ownerIdx}_first`),
		last: selector(state, `owner_${ownProps.ownerIdx}_last`)
	};
};

AddressOwner = connect(mapStateToProps)(AddressOwner);

export default AddressOwner;
