import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, getFormValues } from "redux-form";
import OwnershipIcon from "_icons/OwnershipIcon";
import Indicator from "../Indicator";
import { muiInput } from "_fields/inputs";
import { required, phoneNumber, emailAddress } from "_fields/validation";
import { phone, lowerCase, words } from "_fields/normalizers";

class OwnerInfo extends Component {
  constructor(props) {
    super();

    this.uniqueOwnerPhone = this.uniqueOwnerPhone.bind(this);
    this.uniqueOwnerEmail = this.uniqueOwnerEmail.bind(this);
  }

  /**
   * We are purposefully recalculating this instead of storing in state.
   * When stored in state intital load with invalid values pass validation due
   * to state being set after initial validation check.
   */
  getOtherOwnerValues() {
    let result = {
      phoneNumbers: [],
      emailAddresses: []
    };

    Object.entries(this.props.values).forEach(([key, val]) => {
      // do not add the phone or email for this owner to array
      if (
        key === `owner_${this.props.ownerIdx}_mobile` ||
        key === `owner_${this.props.ownerIdx}_email`
      ) {
        return;
      }

      if (key.endsWith("mobile")) {
        result.phoneNumbers.push(val);
      }

      if (key.endsWith("email")) {
        result.emailAddresses.push(val);
      }
    });

    return result;
  }

  uniqueOwnerPhone(value) {
    let check = this.getOtherOwnerValues();

    const valid =
      value && check.phoneNumbers.includes(value)
        ? "Each owner must have a unique phone number"
        : undefined;

    return valid;
  }

  uniqueOwnerEmail(value) {
    let check = this.getOtherOwnerValues();

    const valid =
      value && check.emailAddresses.includes(value)
        ? "Each owner must have a unique email address"
        : undefined;

    return valid;
  }

  render() {
    const { ownerIdx, theme } = this.props;

    return (
      <React.Fragment>
        <OwnershipIcon fill={theme.primary} />

        <h3>Contact Info</h3>

        <Indicator ownerIdx={ownerIdx} />

        <Field
          name={`owner_${ownerIdx}_first`}
          component={muiInput}
          type="text"
          label="First Name"
          className="input-50 mr"
          validate={[required]}
          normalize={words}
        />

        <Field
          name={`owner_${ownerIdx}_last`}
          component={muiInput}
          type="text"
          label="Last Name"
          className="input-50"
          validate={[required]}
          normalize={words}
        />

        <Field
          name={`owner_${ownerIdx}_mobile`}
          component={muiInput}
          type="tel"
          label="Mobile Phone"
          className="input-100"
          normalize={phone}
          validate={[required, phoneNumber, this.uniqueOwnerPhone]}
        />

        <Field
          name={`owner_${ownerIdx}_email`}
          component={muiInput}
          type="email"
          label="Email"
          className="input-100"
          normalize={lowerCase}
          validate={[required, emailAddress, this.uniqueOwnerEmail]}
        />
      </React.Fragment>
    );
  }
}

OwnerInfo = connect(state => ({
  values: getFormValues("application")(state)
}))(OwnerInfo);

export default OwnerInfo;
