import React from "react";
import { Field } from "redux-form";
import { radioButton } from "_fields/inputs";
import { required } from "_fields/validation";

const TermSelector = (props) => {
  const { termOptions, updatePayment } = props;
  
	return (
			<React.Fragment>
				<fieldset>
					{termOptions.map((term) => (
						<div className="term-radio" key={term}>
							<Field
								component={radioButton}
								type="radio"
								id={"term" + term}
								name="loan_terms"
								value={term.toString()}
								validate={[required]}
								onChange={(event, newValue, previousValue) => {
									window.setTimeout(() => updatePayment(), 0);
								}}
							/>
							<label htmlFor={"term" + term}>{term}</label>
						</div>
					))}
				</fieldset>
			</React.Fragment>
		);
};

export default TermSelector;
