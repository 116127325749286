import React, { Component } from "react";
import GearIcon from "_icons/GearIcon";

class Process extends Component {
	componentDidMount() {
		this.props.decisionCheck();
	}

	render() {
		const { theme } = this.props;

		return (
			<div className="app-section slide">
				<div className="form-width">
					<GearIcon fill={theme.primary} />
					<h3>Processing...</h3>
					<p>We are checking on a few things. Hold tight!</p>
				</div>
			</div>
		);
	}
}

export default Process;
