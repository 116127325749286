import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import lockIcon from "lock-icon.png";
import loading from "loading.gif";
import "ContinueButton.scss";

const ContinueButton = props => {
  const { btnText, invalid, saving, showLock, onClick } = props;

  const buttonText = btnText || "Continue";
  const lockClass = classNames("lock", { hide: !showLock });
  const loaderClass = classNames("load", { hide: !saving });
  const onClickHandler = onClick ? { onClick } : {};

  return (
    <div className="wrap">
      <Button
        variant="contained"
        color="primary"
        className="btn btn-continue"
        type="submit"
        disabled={invalid || saving}
        {...onClickHandler}
      >
        <img
          src={lockIcon}
          className={lockClass}
          width="12px"
          height="17px"
          alt=""
        />
        {buttonText}
        <img
          src={loading}
          className={loaderClass}
          width="24"
          height="24"
          alt=""
        />
      </Button>
    </div>
  );
};

export default ContinueButton;
