import React from "react";
import OwnershipIcon from "_icons/OwnershipIcon";

const AddOwner = props => {
  const { ownerIdx, theme, history } = props;
  const nextOwnerIndex = Number(ownerIdx) + 1;

  return (
    <React.Fragment>
      <OwnershipIcon fill={theme.primary} />
      <h3>Are there any other owners?</h3>

      <div className="add-owner-button-wrap">
        <button
          type="button"
          className="btn btn-add-owner-yes"
          onClick={() => {
            history.push(`/app/owners/${nextOwnerIndex}/info`);
          }}
        >
          Yes
        </button>

        <button type="submit" className="btn-secondary btn-add-owner-no">
          No
        </button>
      </div>
    </React.Fragment>
  );
};

export default AddOwner;
