import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// converted to class to avoid Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()

class SignOutItem extends React.Component {
	render() {
		return (
			<MenuItem onClick={this.props.handleClose}>
				<ListItemIcon>
					<ExitToAppIcon className="flip-horiz" />
				</ListItemIcon>
				<ListItemText inset primary="Sign out" />
			</MenuItem>
		);
	}
}

export default SignOutItem;
