import React from "react";

const WebsiteIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Website Icon</title>
      <path
        fill={props.fill}
        d="M24.56,49.11A24.56,24.56,0,1,1,49.11,24.56,24.58,24.58,0,0,1,24.56,49.11Zm0-47.78A23.23,23.23,0,1,0,47.78,24.56,23.26,23.26,0,0,0,24.56,1.33Zm12.13,13H12.42a1.52,1.52,0,0,0-1.51,1.51V33.26a1.51,1.51,0,0,0,1.51,1.5H23.78l-.06-.44,0-.15-.05-.41H12.42a.5.5,0,0,1-.5-.5V17.49H37.19V33.26a.5.5,0,0,1-.5.5H32.28l.59,1h3.82a1.51,1.51,0,0,0,1.51-1.5V15.81A1.52,1.52,0,0,0,36.69,14.3Zm-5,24.87,1.55-.92a.38.38,0,0,0,.16-.6Q32,35.3,30.63,32.94c-.15-.27-.09-.41.2-.45l2.84-.37L34.84,32a.24.24,0,0,0,.15-.1s0-.11-.07-.15a.48.48,0,0,0-.11-.09L32.7,30l-3.55-2.65L26,25c-.79-.59-1.58-1.18-2.38-1.76a.27.27,0,0,0-.21-.05.23.23,0,0,0-.06.19c0,.35.09.69.13,1l.42,3.25.39,3.06L24.7,34c.14,1.12.28,2.24.43,3.35a.66.66,0,0,0,.11.2.74.74,0,0,0,.17-.12l1.06-1.38,1.37-1.83c.15-.2.32-.2.44,0l1.92,3.26c.29.5.58,1,.88,1.5a1.63,1.63,0,0,0,.31.27C31.52,39.24,31.61,39.22,31.69,39.17Z"
      />
    </svg>
  );
};

export default WebsiteIcon;
