import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Equipment from "./Equipment.jsx";

class EquipmentContainer extends Component {
	constructor(props) {
		super();

		this.saveEquipmentDesc = this.saveEquipmentDesc.bind(this);
	}

	saveEquipmentDesc() {
		this.props.setSaving(true);
		this.props.nextRoute();
		this.props.setSaving(false);
	}

	render() {
		return <Equipment onSubmit={this.saveEquipmentDesc} {...this.props} />;
	}
}

export default connect()(withRouter(EquipmentContainer));
