import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { setTemp } from "redux/actions";
import Decision from "./Decision";

class DecisionContainer extends Component {
	constructor(props) {
		super();

		this.decisionCheck = this.decisionCheck.bind(this);
	}

	async decisionCheck() {
		const post = {
			loan_id: this.props.loan_id,
			loan_type: this.props.loan_type,
			amount: this.props.loan_amount,
			terms: this.props.loan_terms,
			payment: this.props.loan_payment,
			type: this.props.user.type,
		};

		const response = await this.props.callApi("decision/check", post);
		if (!response.error) {
			this.routeDecision(response);
		}
	}

	routeDecision(response) {
		const { history } = this.props;

		// close any existing alerts
		this.props.closeAlert();

		let decision = response.decision;
		
		// Force to proceed for testing
		// decision = "proceed";	

		// use history.replace so that process cannot be back browsed
		switch (decision) {
			case "proceed":
				history.replace("/app/docs/upload");
				break;
			case "decline":
				this.props.change("application", "owner_1_fico", response.fico);
				history.replace("/app/decision/decline");
				break;
			case "frozen":
				history.replace("/app/decision/frozen");
				break;
		}
	}

	render() {
		return <Decision decisionCheck={this.decisionCheck} {...this.props} />;
	}
}

const selector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		fico: selector(state, "owner_1_fico"),
		loan_id: selector(state, "loan_id"),
		loan_type: selector(state, "loan_type"),
		loan_amount: selector(state, "loan_amount"),
		loan_terms: selector(state, "loan_terms"),
		loan_payment: selector(state, "loan_payment"),
		theme: state.theme,
		temp: state.temp,
		user: state.user,
	};
};

export default connect(mapStateToProps, { setTemp, change })(
	withRouter(DecisionContainer)
);
