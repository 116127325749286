import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import BackArrowIcon from "_icons/BackArrowIcon";
import { Link } from "react-router-dom";
import AuthMenu from "../Auth/Menu";
import "AppBar.scss";

let AppBarMain = (props) => {
	const { hideBackArrow, theme, config, match, history } = props;
	let navAction = () => {
		if (match.params.step === "agreement" && match.params.property === "sign") {
			history.replace("/app/agreement/terms");
		} else {
			history.goBack();
		}
	};
	let backArrow =
		hideBackArrow || match.params.step === "done" ? null : (
			<div onClick={() => navAction()} className="back-arrow">
				<BackArrowIcon fill={theme.primary} />
			</div>
		);

	return (
		<React.Fragment>
			<AppBar position="sticky" color="default" className="app-bar">
				<Toolbar>
					{backArrow}
					<Link to="/" className="logo-wrap">
						<img src={config.logo} alt="" className="logo" />
					</Link>
					<AuthMenu />
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
};

AppBarMain = connect((state) => {
	return { theme: state.theme, config: state.config };
})(AppBarMain);

export default withRouter(AppBarMain);
