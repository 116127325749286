import React, { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector, getFormValues, change } from "redux-form";
import { withRouter, Switch } from "react-router-dom";
import PrivateRoute from "_shared/Auth/PrivateRoute";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { setTemp, setUser } from "redux/actions";

import Routes from "services/Routes";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorBoundary from "_shared/Error/Error";
import Alert from "_shared/Alert/Alert";

import LoanContainer from "Portal/Loan";
import AmountContainer from "Portal/Amount";
import EquipmentContainer from "Portal/Equipment";
import InfoContainer from "Portal/Info";
import BusinessContainer from "Portal/Business";
import OwnersContainer from "Portal/Owners";
import DecisionContainer from "Portal/Decision";
import DocsContainer from "Portal/Docs";
import Done from "Portal/Done/Done";

import { ModalManager } from "react-dynamic-modal";
import { ProcessingModal } from "_shared/Modals/Modals";
import { apiService } from "services";

/* this class determines which step to load based on path */
class Application extends Component {
	constructor(props) {
		super(props);

		this.setSaving = this.setSaving.bind(this);
		this.getLoanType = this.getLoanType.bind(this);

		this.nextRoute = this.nextRoute.bind(this);
		this.openAlert = this.openAlert.bind(this);
		this.callApi = this.callApi.bind(this);

		this.state = {
			saving: false,
			alertOpen: false,
			alertMsg: "",
			alertLevel: "warning",
			alertProcessing: false,
			alertFeedbackLink: false,
		};
	}

	setSaving(saving) {
		this.setState({ saving: saving });
	}

	nextRoute(replace = false) {
		this.closeAlert();
		const { history, match, allValues } = this.props;
		const loanType = this.getLoanType();
		Routes.next(history, match, allValues, replace, loanType);
	}

	openAlert = (
		message,
		level = "warning",
		processing = false,
		showFeedback = true
	) => {
		this.setState({
			alertOpen: true,
			alertMsg: message,
			alertLevel: level,
			alertProcessing: processing,
			alertFeedbackLink: showFeedback,
		});
	};

	closeAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.setState({ alertOpen: false });
	};

	// attempt to make best guess at loan type if it is empty
	getLoanType() {
		let loanType = this.props.loan_type;

		// assume EQ
		if (!loanType) {
			// eq terms are 24-60, if less must be WC
			if (this.props.loan_terms < 24) {
				loanType = "wc";
			} else {
				loanType = "eq";
			}

			this.props.change("application", "loan_type", loanType);
		}

		return loanType;
	}

	showProcessingModal(title, msg = null) {
		ModalManager.open(
			<ProcessingModal title={title} msg={msg} onRequestClose={() => false} />
		);
	}

	async callApi(route, data) {
		this.setState({ saving: true });

		// merge in always sent data properties
		const post = {
			account_id: this.props.business_id,
			contact_id: this.props.contact_id,
			vendor_id: this.props.config.id,
			...data,
		};

		const response = await apiService.call(route, post, this.props.user);

		if (response.error) {
			// handle expired token
			if (response.msg && response.msg === "Unauthorized") {
				this.props.history.push("/user/expired");
				return response;
			}

			// handle caught errors
			const message =
				response.msg || "Oops, there was an error on our side. Code: 2";
			const level = response.level || "warning";
			const feedback = response.feedback || false;

			this.openAlert(message, level, false, feedback);
		}

		this.setState({ saving: false });

		return response;
	}

	render() {
		const { location } = this.props;

		const timeout = {
			enter: 250,
			exit: 250,
		};

		return (
			<main className={this.props.theme.name}>
				<TransitionGroup component={null}>
					<CSSTransition
						key={location.key}
						classNames="slide"
						timeout={timeout}
					>
						<ErrorBoundary>
							<Switch location={location}>
								<PrivateRoute
									path={"/app/loan/:type?"}
									component={LoanContainer}
									saving={this.state.saving}
									nextRoute={this.nextRoute}
								/>

								<PrivateRoute
									exact
									path={"/app/amount"}
									component={AmountContainer}
									setSaving={this.setSaving}
									saving={this.state.saving}
									nextRoute={this.nextRoute}
								/>

								<PrivateRoute
									exact
									path={"/app/equipment"}
									component={EquipmentContainer}
									setSaving={this.setSaving}
									saving={this.state.saving}
									nextRoute={this.nextRoute}
								/>

								<PrivateRoute
									exact
									path={"/app/info"}
									component={InfoContainer}
									callApi={this.callApi}
									setSaving={this.setSaving}
									saving={this.state.saving}
									nextRoute={this.nextRoute}
									showProcessingModal={this.showProcessingModal}
								/>

								<PrivateRoute
									exact
									path={"/app/business/:property"}
									component={BusinessContainer}
									callApi={this.callApi}
									setSaving={this.setSaving}
									saving={this.state.saving}
									nextRoute={this.nextRoute}
								/>

								<PrivateRoute
									exact
									path={"/app/owners/:idx/:property"}
									component={OwnersContainer}
									callApi={this.callApi}
									saving={this.state.saving}
									nextRoute={this.nextRoute}
									closeAlert={this.closeAlert}
								/>

								<PrivateRoute
									exact
									path={"/app/decision/:property"}
									component={DecisionContainer}
									callApi={this.callApi}
									setSaving={this.setSaving}
									saving={this.state.saving}
									nextRoute={this.nextRoute}
									closeAlert={this.closeAlert}
								/>

								<PrivateRoute
									exact
									path={"/app/docs/upload"}
									component={DocsContainer}
									callApi={this.callApi}
									setSaving={this.setSaving}
									saving={this.state.saving}
									nextRoute={this.nextRoute}
									openAlert={this.openAlert}
									closeAlert={this.closeAlert}
								/>

								<PrivateRoute exact path={"/app/done"} component={Done} />
							</Switch>
						</ErrorBoundary>
					</CSSTransition>
				</TransitionGroup>

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={this.state.alertOpen}
					onClose={this.closeAlert}
				>
					<Alert
						onClose={this.closeAlert}
						variant={this.state.alertLevel}
						className={this.state.alertLevel}
						message={this.state.alertMsg}
						showProcessing={this.state.alertProcessing}
						showFeedbackLink={this.state.alertFeedbackLink}
					/>
				</Snackbar>
			</main>
		);
	}
}

const selector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		business_id: selector(state, "business_id"),
		contact_id: selector(state, "owner_1_id"),
		loan_type: selector(state, "loan_type"),
		allValues: getFormValues("application")(state) || {},
		theme: state.theme,
		config: state.config,
		temp: state.temp,
		user: state.user,
		data: state.data,
	};
};

export default connect(mapStateToProps, { setTemp, setUser, change })(
	withRouter(Application)
);
