import React from "react";
import { Field } from "redux-form";
import { muiInput, muiSelect } from "_fields/inputs";
import { required } from "_fields/validation";
import { allCaps, words } from "_fields/normalizers";
import MenuItem from "@material-ui/core/MenuItem";

const AddressManual = (props) => {
	const { addressKey, addressType, manualAddress, toggleManualAddress } = props;
	const showManualAddressFields = manualAddress ? "show" : "hide";
	const manualToggleText = manualAddress ? "Use search tool" : "Enter Manually";
	const suiteUnitLabel =
		addressType === "Business" ? "Suite (optional)" : "Unit (optional)";

	return (
		<React.Fragment>
			<div className={showManualAddressFields}>
				<Field
					name={`${addressKey}_street`}
					component={muiInput}
					type="text"
					label="Street"
					className="input-100 manual-addr-street"
					validate={[required]}
					normalize={words}
					readOnly={!manualAddress}
					autoFocus
				/>

				<Field
					name={`${addressKey}_suite`}
					component={muiInput}
					type="text"
					label={suiteUnitLabel}
					className="input-50 mr"
					normalize={words}
					readOnly={!manualAddress}
				/>

				<Field
					name={`${addressKey}_city`}
					component={muiInput}
					type="text"
					label="City"
					className="input-50"
					validate={[required]}
					normalize={words}
					readOnly={!manualAddress}
				/>

				<Field
					name={`${addressKey}_state`}
					component={muiSelect}
					label="State"
					className="input-50 mr mui-input-custom mui-select"
					validate={[required]}
					readOnly={!manualAddress}
				>
					<MenuItem value="">
						<em>Choose</em>
					</MenuItem>
					<MenuItem value="AL">Alabama</MenuItem>
					<MenuItem value="AK">Alaska</MenuItem>
					<MenuItem value="AZ">Arizona</MenuItem>
					<MenuItem value="AR">Arkansas</MenuItem>
					<MenuItem value="CA">California</MenuItem>
					<MenuItem value="CO">Colorado</MenuItem>
					<MenuItem value="CT">Connecticut</MenuItem>
					<MenuItem value="DE">Delaware</MenuItem>
					<MenuItem value="DC">District Of Columbia</MenuItem>
					<MenuItem value="FL">Florida</MenuItem>
					<MenuItem value="GA">Georgia</MenuItem>
					<MenuItem value="HI">Hawaii</MenuItem>
					<MenuItem value="ID">Idaho</MenuItem>
					<MenuItem value="IL">Illinois</MenuItem>
					<MenuItem value="IN">Indiana</MenuItem>
					<MenuItem value="IA">Iowa</MenuItem>
					<MenuItem value="KS">Kansas</MenuItem>
					<MenuItem value="KY">Kentucky</MenuItem>
					<MenuItem value="LA">Louisiana</MenuItem>
					<MenuItem value="ME">Maine</MenuItem>
					<MenuItem value="MD">Maryland</MenuItem>
					<MenuItem value="MA">Massachusetts</MenuItem>
					<MenuItem value="MI">Michigan</MenuItem>
					<MenuItem value="MN">Minnesota</MenuItem>
					<MenuItem value="MS">Mississippi</MenuItem>
					<MenuItem value="MO">Missouri</MenuItem>
					<MenuItem value="MT">Montana</MenuItem>
					<MenuItem value="NE">Nebraska</MenuItem>
					<MenuItem value="NV">Nevada</MenuItem>
					<MenuItem value="NH">New Hampshire</MenuItem>
					<MenuItem value="NJ">New Jersey</MenuItem>
					<MenuItem value="NM">New Mexico</MenuItem>
					<MenuItem value="NY">New York</MenuItem>
					<MenuItem value="NC">North Carolina</MenuItem>
					<MenuItem value="ND">North Dakota</MenuItem>
					<MenuItem value="OH">Ohio</MenuItem>
					<MenuItem value="OK">Oklahoma</MenuItem>
					<MenuItem value="OR">Oregon</MenuItem>
					<MenuItem value="PA">Pennsylvania</MenuItem>
					<MenuItem value="RI">Rhode Island</MenuItem>
					<MenuItem value="SC">South Carolina</MenuItem>
					<MenuItem value="SD">South Dakota</MenuItem>
					<MenuItem value="TN">Tennessee</MenuItem>
					<MenuItem value="TX">Texas</MenuItem>
					<MenuItem value="UT">Utah</MenuItem>
					<MenuItem value="VT">Vermont</MenuItem>
					<MenuItem value="VA">Virginia</MenuItem>
					<MenuItem value="WA">Washington</MenuItem>
					<MenuItem value="WV">West Virginia</MenuItem>
					<MenuItem value="WI">Wisconsin</MenuItem>
					<MenuItem value="WY">Wyoming</MenuItem>
				</Field>

				<Field
					name={`${addressKey}_zip`}
					component={muiInput}
					type="text"
					label="Zipcode"
					className="input-50"
					validate={[required]}
					normalize={words}
					readOnly={!manualAddress}
				/>
			</div>

			<div className="manual-address-override">
				<div className="faux-link" onClick={toggleManualAddress}>
					{manualToggleText}
				</div>
			</div>
		</React.Fragment>
	);
};

export default AddressManual;
