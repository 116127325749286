import { reset } from "redux-form";
import { logout, clearTemp } from "redux/actions";
import { loadDefaultVendor } from "actions";

export const handleSignOut = (dispatch) => {
	dispatch(logout());
	dispatch(reset("application"));
	dispatch(reset("login"));
	dispatch(loadDefaultVendor(true));
	dispatch(clearTemp());
};
