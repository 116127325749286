import React from "react";

const AddressIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Address Icon</title>
      <path
        fill={props.fill}
        d="M24.56 49.11a24.56 24.56 0 1 1 24.55-24.55 24.58 24.58 0 0 1-24.55 24.55zm0-47.78a23.23 23.23 0 1 0 23.22 23.23A23.26 23.26 0 0 0 24.56 1.33zm10.65 19v-.83-.3c0-.1 0-.33-.07-.5a10.76 10.76 0 0 0-1.2-3.43 1.86 1.86 0 0 1-.11-.19c-.14-.24-.29-.47-.45-.7a9.71 9.71 0 0 0-1.95-2.14l-.49-.37a10.62 10.62 0 0 0-14.64 2l-.05.06c-.19.25-.38.51-.55.78a.41.41 0 0 0-.07.11 8.46 8.46 0 0 0-.44.77 1.63 1.63 0 0 0-.09.19c-.12.24-.24.49-.34.74a2.47 2.47 0 0 0-.1.29c-.08.22-.16.44-.23.67s-.07.28-.11.42-.09.38-.13.58-.19.35-.19.52 0 .34-.07.51V21.08c0 .16 0 .45.05.67s0 .16 0 .25c0 .3.09.6.16.9s0 0 0 .06c.08.31.16.61.26.91a28.42 28.42 0 0 0 2.41 5.62 62.39 62.39 0 0 0 6.43 9.28c.4.5.82 1 1.24 1.48l.23-.25a67.53 67.53 0 0 0 6.45-8.77 32.7 32.7 0 0 0 3.23-6.65 16.6 16.6 0 0 0 .47-1.67c.06-.24.1-.49.14-.74a8.85 8.85 0 0 0 .11-.9 6.51 6.51 0 0 0 0-.77v-.05c0-.05.1-.07.1-.12zm-10.65 4.34a4.2 4.2 0 1 1 4.19-4.2 4.19 4.19 0 0 1-4.19 4.2z"
      />
    </svg>
  );
};

export default AddressIcon;
