const Routes = {
	routes: [
		"loan",
		"amount",
		"equipment",
		"info",
		"business/years",
		"business/revenue",
		"business/phone",
		"business/address",
		"business/based",
		"business/ein",
		"business/website",
		"owners/:idx/info",
		"owners/:idx/ownership",
		"owners/:idx/address",
		"owners/:idx/identity",
		"owners/:idx/add",
		"decision/process",
		"docs/upload",
		"done",
	],

	/**
	 * Determines the next route to load
	 * @param {Object} history - history api object
	 * @param {Object} match - Prop from withRouter decorated object
	 * @param {Object} allValues - reduxForm state, used to assist in routing logic
	 * @param {Boolean} replace - whether to use history.push or history.replace
	 * @param {String} loanType - wc or eq - skip equipment page for WC loans
	 */
	next(history, match, allValues, replace = false, loanType = "wc") {
		const { step, property, idx } = match.params;

		// determine current route
		let current = step;
		current += idx ? "/:idx" : "";
		current += property ? `/${property}` : "";

		// load the next route
		let nextIndex = this.routes.indexOf(current) + 1;
		let nextRoute = this.routes[nextIndex];

		// need to generate the index for first owner
		let ownerIndex = idx ? idx : "1";

		const owner = this.getOwnerData(match, allValues);

		nextRoute = this.checkSkipStep(
			nextIndex,
			nextRoute,
			ownerIndex,
			owner,
			loanType
		);

		// check to see if we need to increment ownerIdx
		ownerIndex =
			nextRoute === "owners/:idx/info"
				? Number(++ownerIndex)
				: Number(ownerIndex);

		// if on an indexed step we need to replace :idx with the actual ownerIndex
		nextRoute = nextRoute.replace(":idx", ownerIndex);

		// navigate to next url
		if (replace) {
			history.replace(`/app/${nextRoute}`);
		} else {
			history.push(`/app/${nextRoute}`);
		}
	},

	getOwnerData(match, allValues) {
		return {
			homeBased: allValues.home_based,
			maxIndex: this.calcMaxOwnerIndex(match, allValues),
			totalOwnership: this.calcTotalOwnershipPercentage(match, allValues),
		};
	},

	/**
	 * This is a helper method to determine highest indexed owner in state.
	 * It is used to determine if the Route class should skip add owner page.
	 */
	calcMaxOwnerIndex(match, allValues) {
		const {
			params: { step, property },
		} = match;

		// only relevant if we are on identity step and about to load add step
		if (step === "owners" && property === "identity") {
			let ownerIndexes = [];
			Object.entries(allValues).forEach(([key, val]) => {
				if (
					key.startsWith("owner_") &&
					ownerIndexes.indexOf(Number(key.slice(6, 7))) === -1
				) {
					ownerIndexes.push(Number(key.slice(6, 7)));
				}
			});

			return Math.max(...ownerIndexes);
		}

		return false;
	},

	calcTotalOwnershipPercentage(match, allValues) {
		const {
			params: { step },
		} = match;

		let total = 0;
		if (step === "owners") {
			Object.entries(allValues).forEach(([key, val]) => {
				if (key.startsWith("owner_") && key.endsWith("_ownership")) {
					total += Number(val);
				}
			});
		}
		return total;
	},

	/**
	 * There are several conditions where we must skip a step
	 * 1. First owner does not fill out info step. (/app/owners/:idx/info)
	 * 2. If the business is home based the first owner does not fill out address.
	 * 3. If total ownership is 100, skip add owner step
	 * 4. If state contains an owner beyond current index, skip add owner step.
	 * 5. If the user is already logged in we need to skip verify steps
	 */
	checkSkipStep(nextIndex, nextRoute, ownerIndex, owner, loanType) {
		const { homeBased, maxIndex, totalOwnership } = owner;

		// get values for next path so we can check if we need to skip the step
		const nextRouteParts = nextRoute.split("/");
		let nextStep = nextRouteParts[0];
		let nextProp = nextRouteParts[2];

		// conditionally skip some steps for owners
		if (nextStep === "owners") {
			// skip owners/:idx/info route for first owner
			nextIndex =
				nextProp === "info" && ownerIndex === "1" ? ++nextIndex : nextIndex;

			// skip owners/:idx/address for first owner if business is home based
			nextIndex =
				nextProp === "address" && ownerIndex === "1" && homeBased === "yes"
					? ++nextIndex
					: nextIndex;

			// skip add owner page if total ownership is already 100
			nextIndex =
				nextProp === "add" && totalOwnership >= 100
					? this.routes.indexOf("decision/process")
					: nextIndex;

			// skip add owner page if state contains owner beyond current index
			nextIndex =
				nextProp === "add" && ownerIndex < maxIndex
					? this.routes.indexOf("owners/:idx/info")
					: nextIndex;

			// update the next path
			nextRoute = this.routes[nextIndex];
		}

		if (nextStep === "equipment") {
			if (loanType !== "eq") {
				nextRoute = "info";
			}
		}

		return nextRoute;
	},
};

export default Routes;
