import React from "react";
import { reduxForm } from "redux-form";
import { customInput, rangeInput } from "_fields/inputs";
import { required } from "_fields/validation";
import { currency } from "_fields/normalizers";
import { parseCurrency } from "_fields/parsers";
import { Field } from "redux-form";
import TermSelector from "./TermSelector";
import ContinueButton from "_shared/Continue/Button";
import "Amount.scss";

let Amount = (props) => {
  const { min, max, termOptions, rate } = props.config[props.loanType];
  const loanText =
		props.loanType === "eq" ? "Equipment Loan" : "Working Capital";

	return (
		<div className="app-section slide">
			<form onSubmit={props.handleSubmit}>
				<h4 className="loanText">{loanText}</h4>

				<h3>How much is your customer looking for?</h3>

				<Field
					name="loan_amount"
					component={customInput}
					className="amount"
					type="tel"
					placeholder="Enter Amount"
					validate={[required]}
					format={currency}
					parse={parseCurrency}
				/>

				<div className="slider">
					<span className="low">${min / 1000}K</span>
					<Field name="loan_amount" component={rangeInput} min={min} max={max} />
					<span className="high">${max / 1000}K</span>
				</div>

				<h5 className="termPaymentTitle">
					Select term <span className="muted">(in months)</span>
				</h5>

				<TermSelector
					termOptions={termOptions}
					loanAmount={props.loanAmount}
					loanType={props.loanType}
					rate={rate}
					updatePayment={props.updatePayment}
				/>

				<ContinueButton invalid={props.invalid} saving={props.saving} />
			</form>
		</div>
	);
};

Amount = reduxForm({
	form: "application",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(Amount);

export default Amount;
