import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { ModalManager } from "react-dynamic-modal";
import { handleSignOut } from "utils/reduxUtils";

let Expired = (props) => {
	// close any lingering processing modals after a redirect to expired page
	ModalManager.close();

	handleSignOut(props.dispatch);

	return (
		<div className="app-section">
			<h3>Login Expired</h3>

			<p>
				You are now signed out. We have automatically cleared sensitive data
				from your browser.
			</p>

			<p>
				If for any reason you need to sign back in you can do so here.{" "}
				<Link to="/">Sign in</Link>
			</p>
		</div>
	);
};

Expired = reduxForm({
	form: "application",
})(Expired);

export default connect()(Expired);
