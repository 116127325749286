import React from "react";
import { reduxForm } from "redux-form";
import Question from "./Question";
import ContinueButton from "_shared/Continue/Button";
import "Owners.scss";

let Owners = (props) => {
	const { handleSubmit, match, invalid, saving } = props;
  const question = match.params.property;
  const { idx } = match.params;
	const continueButton =
		match.params.property === "add" ? null : (
			<ContinueButton invalid={invalid} saving={saving} />
		);

	return (
		<div className="app-section slide">
			<form onSubmit={handleSubmit}>
				<Question
					theme={props.theme}
					question={question}
					history={props.history}
					ownerIdx={idx}
					change={props.change}
					invalid={invalid}
					handleSubmit={handleSubmit}
					nextRoute={props.nextRoute}
				/>

				{continueButton}
			</form>
		</div>
	);
};

Owners = reduxForm({
	form: "application",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(Owners);

export default Owners;
