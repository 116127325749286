import React from "react";

const HomeBasedIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Home Business Icon</title>
      <path
        fill={props.fill}
        d="M24.56,49.11A24.56,24.56,0,1,1,49.11,24.56,24.58,24.58,0,0,1,24.56,49.11Zm0-47.78A23.23,23.23,0,1,0,47.78,24.56,23.26,23.26,0,0,0,24.56,1.33ZM12,21.24c-.92.45-1.64.25-1.85-.47a1.45,1.45,0,0,1,.05-.77c.52-1.57,1.09-3.13,1.59-4.71a5.75,5.75,0,0,0,.2-1.56c0-1,.4-1.38,1.34-1.38H35.71c.91,0,1.34.42,1.3,1.35a5.52,5.52,0,0,0,.37,2.09c.5,1.37.94,2.75,1.43,4.13.19.51.25,1-.2,1.36a1.11,1.11,0,0,1-1.37,0,1.11,1.11,0,0,0-.22-.09V35c0,1.12-.37,1.5-1.47,1.5H33.63V25H27.94V36.47H13.48C12.42,36.47,12,36.08,12,35V21.24ZM15.47,33h9V25h-9Zm7.61-17.22c0,1.7-.13,3.32-.11,4.94a1.58,1.58,0,0,0,3.14-.13c0-1.49-.08-3-.14-4.47,0-.12-.19-.32-.3-.32C24.84,15.76,24,15.77,23.08,15.77Zm9.59,0c0,.21,0,.35,0,.5l.9,4.48A1.6,1.6,0,1,0,36.73,20c-.38-1.27-.75-2.54-1.14-3.81-.05-.15-.21-.39-.33-.39C34.41,15.76,33.56,15.77,32.67,15.77Zm-16.23,0c-.88,0-1.67,0-2.45,0-.32,0-.45.11-.54.42-.36,1.31-.76,2.6-1.13,3.91a1.49,1.49,0,0,0-.05.63,1.56,1.56,0,0,0,1.62,1.35,1.58,1.58,0,0,0,1.54-1.4c.1-.55.2-1.11.31-1.66C16,18,16.19,16.92,16.44,15.77Zm11.47,0a.77.77,0,0,0,0,.22c.13,1.57.24,3.14.41,4.7a1.56,1.56,0,0,0,1.8,1.36,1.53,1.53,0,0,0,1.37-1.7c-.19-1.44-.46-2.87-.71-4.31a.47.47,0,0,0-.32-.26C29.6,15.76,28.77,15.77,27.91,15.77Zm-6.71,0c-.92,0-1.75,0-2.58,0a.46.46,0,0,0-.31.27c-.1.45-.17.91-.24,1.37-.16,1-.37,2-.45,3a1.5,1.5,0,0,0,1.51,1.61,1.6,1.6,0,0,0,1.66-1.54c0-.29,0-.59.07-.89C21,18.37,21.08,17.11,21.2,15.77Z"
      />
    </svg>
  );
};

export default HomeBasedIcon;
