import React from "react";
import { Modal, Effect } from "react-dynamic-modal";
import styles from "scss/styles.scss";
import "Modals.scss";

export const BasicModal = (props) => {
	const { title, body, effect, onRequestClose } = props;
	return (
		<Modal
			style={{ content: { width: "280px" } }}
			onRequestClose={onRequestClose}
			effect={effect}
		>
			<h3>{title}</h3>
			<div>{body}</div>
		</Modal>
	);
};

export const ProcessingModal = (props) => {
	const { title, msg } = props;
	const message = msg ? msg : "This shouldn't take long.";
	return (
		<Modal
			effect={Effect.Fall}
			onRequestClose={props.onRequestClose}
			style={{
				overlay: { backgroundColor: styles.primaryRgba },
				content: {
					boxShadow: "none",
					background: "transparent",
					border: "none",
					margin: "35px auto",
					width: "100%",
				},
			}}
		>
			<div className="processing-modal">
				<img src="/img/icon-white.png" alt="icon" className="icon-white" />

				<h1 className="title">{title}</h1>

				<div className="container">
					<div className="loader">
						<div className="loader--dot"></div>
						<div className="loader--dot"></div>
						<div className="loader--dot"></div>
						<div className="loader--dot"></div>
						<div className="loader--dot"></div>
						<div className="loader--dot"></div>
						<div className="loader--text"></div>
					</div>
				</div>

				<p>
					{message}
					<br />
					We appreciate your patience.
				</p>
			</div>
		</Modal>
	);
};
