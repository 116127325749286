import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import CompleteIcon from "_icons/CompleteIcon";
import "Done.scss";

const Done = (props) => {
	const loanText =
		props.loanType === "eq" ? "equipment financing" : "working capital";

	return (
		<div className="app-section slide">
			<div className="form-width">
				<CompleteIcon fill={props.theme.primary} />
				<h3>Congratulations!</h3>
				<div className="left">
					<p>Your {loanText} application is complete!</p>
					<p>
						We will begin working on your customer’s approval and will be in
						touch within a business day.
					</p>
				</div>

				<Link to="/user/signout">
					<button className="btn btn-logout">Logout</button>
				</Link>

				<Link to="/user/dashboard" className="dashboard-link">
					<button className="btn">Dashboard</button>
				</Link>
			</div>
		</div>
	);
};

const selector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		loanType: selector(state, "loan_type"),
		theme: state.theme,
	};
};

export default connect(mapStateToProps)(withRouter(Done));
