import React from "react";
import { Field } from "redux-form";
import { customInput } from "_fields/inputs";
import { required } from "_fields/validation";
import CalendarIcon from "_icons/CalendarIcon";

const Years = props => {
  return (
    <React.Fragment>
      <CalendarIcon fill={props.theme.primary} />
      <h3>Years in business</h3>

      <Field
        name="business_years"
        component={customInput}
        type="tel"
        validate={[required]}
        autoFocus
      />
    </React.Fragment>
  );
};

export default Years;
