import React from "react";

const PhoneIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Phone Icon</title>
      <path
        fill={props.fill}
        d="M24.56,49.11A24.56,24.56,0,1,1,49.11,24.56,24.58,24.58,0,0,1,24.56,49.11Zm0-47.78A23.23,23.23,0,1,0,47.78,24.56,23.26,23.26,0,0,0,24.56,1.33ZM15.85,36.19a6.82,6.82,0,0,0,3.85.18,14.32,14.32,0,0,0,4.57-1.85,28.44,28.44,0,0,0,5.35-4.24,30.25,30.25,0,0,0,4.86-6,13,13,0,0,0,2.06-5.84,6.24,6.24,0,0,0-.42-2.87c-.1-.24-.25-.46-.38-.71l-5.94,6a4.27,4.27,0,0,1,.26.57,1.46,1.46,0,0,1-.22,1.17,16.36,16.36,0,0,1-1,1.46,29.42,29.42,0,0,1-5.25,5,6.15,6.15,0,0,1-1.55.95,1.1,1.1,0,0,1-1.23-.19l0,0-5.85,5.86A9,9,0,0,0,15.85,36.19Zm19.3-21.83L33.7,12.9a.94.94,0,0,0-1.46,0L29.8,15.34l-2.13,2.12a.88.88,0,0,0-.09,1.25c.55.59,1.14,1.14,1.72,1.72l5.94-6A.41.41,0,0,0,35.15,14.36ZM18.64,27.71a.89.89,0,0,0-1.27,0l-4.5,4.48a.92.92,0,0,0,0,1.4l1.49,1.48.11.1,5.82-5.83C19.75,28.82,19.21,28.25,18.64,27.71Z"
      />
    </svg>
  );
};

export default PhoneIcon;
