import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ProgressBar } from "react-step-progress-bar";
import Routes from "services/Routes";

class Progress extends Component {
	constructor(props) {
		super(props);

		this.calculatePercentage = this.calculatePercentage.bind(this);
	}

	calculatePercentage() {
		const { match } = this.props;
		const { step, idx, property } = match.params;

		// determine current path
		let currentPath = step;
		currentPath += idx ? "/:idx" : "";
		currentPath += property ? `/${property}` : "";

		const index = Routes.routes.indexOf(currentPath) + 1;
		// subtracting 3 from length so that last 3 bank statement steps display 100%
		// before this we were still subtracting 1 from length since index is 0 based
		const percentage = (index / (Routes.routes.length - 3)) * 100;

		// this will force the progress bar to show at least some green
		return percentage > 100 ? 100 : percentage < 5 ? 5 : percentage;
	}

	render() {
		const completionPercentage = this.calculatePercentage();

		return (
			<ProgressBar
				percent={completionPercentage}
				filledBackground={this.props.theme.primary}
				height={3}
			/>
		);
	}
}

Progress = connect((state) => {
	return {
		theme: state.theme,
	};
})(Progress);

export default withRouter(Progress);
