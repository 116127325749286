import React from "react";
import { Field } from "redux-form";
import { customInput } from "_fields/inputs";
import { required } from "_fields/validation";
import { lowerCase } from "_fields/normalizers";
import WebsiteIcon from "_icons/WebsiteIcon";

const Website = props => {
  return (
    <React.Fragment>
      <WebsiteIcon fill={props.theme.primary} />
      <h3>Website</h3>

      <Field
        name="business_website"
        component={customInput}
        type="text"
        placeholder="https://"
        validate={[required]}
        normalize={lowerCase}
        hideError
        autoFocus
      />
    </React.Fragment>
  );
};

export default Website;
