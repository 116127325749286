import React, { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { EmitterProvider, Emitter } from "react-emitter";
import { withRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "_shared/Auth/PrivateRoute";
import { setTheme, setConfig, setData, setUser, setValidationType, setTemp} from "redux/actions";
import Theme from "services/Theme";
import ErrorBoundary from "_shared/Error/Error";
import AppBarMain from "_shared/AppBar/Main";
import SigninEmail from "./SigninEmail";
import SigninPhone from "./SigninPhone";
import Signout from "./Signout";
import Expired from "./Expired";
import Verify from "./Verify";
import Dashboard from "./Dashboard";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "_shared/Alert/Alert";
import Footer from "./Footer";
import { apiService } from "services/";
import "./User.scss";

class User extends Component {
	constructor(props) {
		super();

		Theme.load(props.setTheme, props.match, props.theme);

		this.signinRequestMobile = this.signinRequestMobile.bind(this);
		this.signinRequestEmail = this.signinRequestEmail.bind(this);
		this.signinVerify = this.signinVerify.bind(this);
		this.callApi = this.callApi.bind(this);
		this.state = {
			saving: false,
			alertOpen: false,
			alertMsg: "",
			alertLevel: "warning",
			alertProcessing: false,
			alertFeedbackLink: false,
		};
	}

	openAlert = (
		message,
		level = "warning",
		processing = false,
		showFeedback = true
	) => {
		this.setState({
			alertOpen: true,
			alertMsg: message,
			alertLevel: level,
			alertProcessing: processing,
			alertFeedbackLink: showFeedback,
		});
	};

	closeAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.setState({ alertOpen: false });
	};

	async signinRequestMobile(form) {
		const response = await this.callApi("user/request", {
			mobile: form.mobile || this.props.mobile,
			vendor_id: this.props.config.id,
		});

		if (!response.error) {
			this.props.setTemp(response);
			this.props.history.push("/user/verify");
			this.props.setValidationType("text");
		}
	}

	async signinRequestEmail(form) {
		const response = await this.callApi("user/requestEmail", {
			email: form.email || this.props.email,
			vendor_id: this.props.config.id,
		});

		if (!response.error) {
			this.props.setTemp(response);
			this.props.history.push("/user/verify");
			this.props.setValidationType("email");
		}
	}

	async signinVerify(form) {
		const post = {
			contact_id: this.props.temp.contact_id,
			contact_type: this.props.temp.contact_type,
			vendor_id: this.props.config.id,
			code: form.verify_code,
		};

		const response = await this.callApi("user/verify", post);

		if (response.error) {
			this.props.emit("clearVerifyCode");
		} else {
			this.closeAlert();
			this.props.emit("clearVerifyCode");
			this.props.setUser(response);

			this.props.setConfig({
				...response.config,
				customVendor: true,
				loaded: true,
			});
			this.props.setData(response);
			this.props.history.push("/user/dashboard");
		}
	}

	async callApi(route, data) {
		this.setState({ saving: true });
		this.closeAlert();

		const response = await apiService.call(route, data);

		if (response.error) {
			// handle caught errors
			const message =
				response.msg || "Oops, there was an error on our side. Code: 3";
			const level = response.level || "warning";
			const feedback = response.feedback || false;

			this.openAlert(message, level, false, feedback);
		}

		this.setState({ saving: false });

		return response;
	}

	render() {
		const { location, signInType } = this.props;
		return (
			<React.Fragment>
				<AppBarMain />
				<main className={this.props.theme.name}>
					<ErrorBoundary>
						<Switch location={location}>
							<Route
								exact
								path="/"
								render={() => (
									<SigninEmail 
										onSubmit={this.signinRequestEmail} 
										saving={this.state.saving} 
										theme={this.props.theme} 
									/>
								)}
							/>
							<Route
								exact
								path="/user/signin-mobile"
								render={() => (
									<SigninPhone
										onSubmit={this.signinRequestMobile}
										saving={this.state.saving}
										theme={this.props.theme}
									/>
								)}
							/>
							<Route
								exact
								path="/user/verify"
								render={() => (
									<Verify
										onSubmit={this.signinVerify}
										verifyRequest={
											signInType.type === "text"
												? this.signinRequestMobile
												: signInType.type === "email"
													? this.signinRequestEmail
													: null
										}
										saving={this.state.saving}
										openAlert={this.openAlert}
										closeAlert={this.closeAlert}
										mobile={signInType.type === "text" ? this.props.mobile : null}
										email={signInType.type === "email" ? this.props.email : null}
									/>
								)}
							/>
							<Route
								exact
								path="/user/signout"
								render={() => <Signout theme={this.props.theme} />}
							/>
							<Route exact path="/user/expired" render={() => <Expired />} />

							<PrivateRoute
								exact
								path="/user/dashboard"
								component={Dashboard}
								user={this.props.user}
								theme={this.props.theme}
							/>
						</Switch>
					</ErrorBoundary>

					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						open={this.state.alertOpen}
						onClose={this.closeAlert}
					>
						<Alert
							onClose={this.closeAlert}
							variant={this.state.alertLevel}
							className={this.state.alertLevel}
							message={this.state.alertMsg}
							showProcessing={this.state.alertProcessing}
							showFeedbackLink={this.state.alertFeedbackLink}
						/>
					</Snackbar>
				</main>
				<Footer />
			</React.Fragment>
		);
	}
}

const selector = formValueSelector("login");

const mapStateToProps = (state) => {
	return {
		mobile: selector(state, "mobile"),
		email: selector(state, "email"),
		theme: state.theme,
		signInType: state.signInType,
		user: state.user,
		config: state.config,
		data: state.data,
		temp: state.temp,
	};
};

User = Emitter(User);

User = connect(mapStateToProps, {
	setTheme,
	setConfig,
	setData,
	setUser,
	setValidationType,
	setTemp,
})(User);

export default withRouter(EmitterProvider(User));
