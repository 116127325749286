import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import ProfileIcon from "_icons/ProfileIcon";
import SignInItem from "./SignInItem";
import SignOutItem from "./SignOutItem";
import DashboardItem from "./DashboardItem";
import "AuthMenu.scss";

class AuthMenu extends Component {
	state = {
		authMenu: null,
	};

	handleClick = (event) => {
		this.setState({ authMenu: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ authMenu: null });
	};

	handleSignOut = () => {
		this.setState({ authMenu: null });
		this.props.history.push("/user/signout");
	};

	handleSignIn = () => {
		this.setState({ authMenu: null });
		this.props.history.push("/user/signin");
	};

	handleDashboardNav = () => {
		this.props.history.push("/user/dashboard");
	};

	render() {
		const { theme, user } = this.props;

		let actionItems = user.loggedIn ? (
			[
				<SignOutItem key="signout" handleClose={this.handleSignOut} />,
				<DashboardItem key="dashboard" handleClose={this.handleDashboardNav} />,
			]
		) : (
			<SignInItem handleClose={this.handleSignIn} />
		);

		return (
			<div className="profile-menu">
				<Button
					className="profile-button"
					aria-owns={this.state.authMenu ? "auth-menu" : undefined}
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					<ProfileIcon fill={user.loggedIn ? theme.primary : theme.secondary} />
				</Button>
				<Menu
					id="auth-menu"
					anchorEl={this.state.authMenu}
					open={Boolean(this.state.authMenu)}
					onClose={this.handleClose}
				>
					{actionItems}
				</Menu>
			</div>
		);
	}
}

AuthMenu = connect((state) => {
	return {
		theme: state.theme,
		user: state.user,
	};
})(AuthMenu);

export default withRouter(AuthMenu);
