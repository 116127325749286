import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "Footer.scss";

let Footer = () => {
	const copyrightDate = new Date().getFullYear();
	const privacyLink = `${VENDOR_URL}/privacy-policy`;
	const termsLink = `${VENDOR_URL}/terms`;
	return (
		<footer className="group footer">
			<Link to="/">
				<img src="/img/logo.png" alt="Logo" className="logo" />
			</Link>

			<div className="phone">
				<a href="tel:1-877-822-1333">(877) 822-1333</a>
			</div>

			<div className="email">
				<a href="mailto:info@tritoncptl.com">info@tritoncptl.com</a>
			</div>

			<div className="address">
				1660 Hotel Circle N<br />
				Suite 215
				<br />
				San Diego, CA, 92108
			</div>

			<a href={privacyLink} target="_blank" rel="noopener noreferrer">
				Privacy Policy
			</a>
			<a
				href={termsLink}
				target="_blank"
				rel="noopener noreferrer"
				className="bl"
			>
				Terms of Use
			</a>

			<p>
				Copyright {copyrightDate}. All rights reserved.
				<br />
				CFLL No. 60DBO42128
			</p>
		</footer>
	);
};

Footer = connect((state) => {
	return { config: state.config };
})(Footer);

export default Footer;
