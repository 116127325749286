import React from "react";
import Script from "react-load-script";
import ErrorBoundary from "_shared/Error/Error";
import AppBarMain from "_shared/AppBar/Main";
import Progress from "Portal/Progress";
import Application from "Portal/Application";

const Portal = (props) => {
	return (
		<ErrorBoundary>
			<Progress />
			<AppBarMain />
			<Application />
			<Script
				url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`}
			/>
		</ErrorBoundary>
	);
};

export default Portal;
