import React from "react";
import { Field, reduxForm } from "redux-form";
import { customInput } from "_fields/inputs";
import { required } from "_fields/validation";
import ContinueButton from "_shared/Continue/Button";

let Equipment = (props) => {
	const { handleSubmit, invalid, saving } = props;

	return (
		<div className="app-section slide">
			<form onSubmit={handleSubmit}>
				<h3>
					What type of equipment is your customer interested in financing?
				</h3>

				<Field
					name="equipment_description"
					component={customInput}
					type="text"
					validate={[required]}
					autoFocus
				/>

				<ContinueButton invalid={invalid} saving={saving} />
			</form>
		</div>
	);
};

Equipment = reduxForm({
	form: "application",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(Equipment);

export default Equipment;
