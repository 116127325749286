import React from "react";

const ProfileIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="32px"
      viewBox="0 0 30 32"
    >
      <g transform="scale(0.1)">
        <path
          fill={props.fill}
          d="M213.31,185.877l-0.81-0.366c-1.934-0.88-3.882-1.71-5.868-2.487c-2.166-0.868-4.364-1.671-6.594-2.423
        c-1.761-0.578-3.535-1.118-5.328-1.645c-2.731-0.79-5.489-1.504-8.329-2.134c-0.964-0.199-1.947-0.392-2.924-0.566l-0.668-0.129
        c-1.677-0.328-3.361-0.617-5.064-0.868l-0.488-0.077c-0.019,0-0.032-0.013-0.051-0.013l-0.488-0.064
        c-1.414-0.212-2.821-0.411-4.287-0.578l-4.987-0.411c-6.131-0.405-12.449-0.392-18.509,0.051l-1.767,0.148l-0.823,0.064
        l-2.063,0.186c-1.896,0.199-3.779,0.463-5.662,0.765l-0.553,0.077c-1.285,0.212-2.571,0.45-3.837,0.688
        c-1.407,0.251-2.796,0.527-4.184,0.829c-2.686,0.604-5.354,1.292-7.937,2.044c-2.057,0.591-4.087,1.24-5.996,1.896l-1.324,0.476
        c-4.049,1.433-8.072,3.078-11.967,4.884l-1.041,0.495l-0.019,0.026c-45.547,21.8-74.918,68.247-74.918,118.646
        c0,1.157,0.077,2.294,0.148,3.438l0.341,7.995H289.5l0.353-7.905c0.077-1.17,0.148-2.333,0.148-3.528
        C290.001,254.188,259.917,207.33,213.31,185.877z M39.682,303.97c0.553-45.251,27.308-86.762,68.086-105.882l1.311-0.514
        l0.154-0.148c3.194-1.459,6.491-2.789,9.833-3.965l1.06-0.379c1.819-0.63,3.631-1.208,5.514-1.742
        c2.346-0.688,4.737-1.305,7.134-1.844c1.24-0.263,2.481-0.514,3.76-0.752c1.015-0.186,2.024-0.379,3.046-0.54l0.501-0.09
        c1.838-0.289,3.683-0.553,5.521-0.752l4.203-0.366c5.501-0.405,10.765-0.463,17.14,0l4.087,0.341
        c1.009,0.116,2.018,0.251,3.246,0.424l1.472,0.212c1.536,0.225,3.072,0.488,4.614,0.778l0.72,0.135
        c0.868,0.161,1.729,0.328,2.545,0.501c2.532,0.553,5.019,1.208,7.487,1.922c1.632,0.476,3.252,0.964,4.833,1.491
        c2.005,0.668,3.985,1.395,5.945,2.172c1.883,0.739,3.74,1.53,5.578,2.372l0.321,0.148c41.704,19.133,68.786,60.779,69.339,106.467
        H39.682V303.97z M126.644,157.599c0.964,0.405,1.96,0.758,2.956,1.112l2.089,0.758c0.803,0.308,1.613,0.611,2.436,0.868
        c7.661,2.41,15.675,3.65,23.824,3.715l0.649,0.013c2.796,0,5.598-0.148,8.284-0.424c0.925-0.09,1.825-0.238,2.731-0.392
        l2.012-0.302c1.131-0.148,2.262-0.315,3.445-0.546c0.81-0.167,1.594-0.379,2.378-0.591l2.198-0.559
        c1.086-0.263,2.172-0.527,3.246-0.861c0.668-0.212,1.324-0.456,1.973-0.701l1.767-0.636c1.26-0.437,2.532-0.887,3.805-1.42
        c0.495-0.212,0.977-0.443,1.452-0.675l0.572-0.283c1.742-0.778,3.477-1.575,5.199-2.5l1.118-0.668
        c1.928-1.093,3.85-2.224,5.797-3.554c1.009-0.681,1.973-1.446,2.924-2.204l3.291-2.558c18.921-15.662,29.795-38.645,29.795-62.482
        l0.039-0.681c0-45.232-36.8-82.026-82.032-82.026c-43.175,0-79.095,33.76-81.775,76.859l-0.071,1.067
        c-0.103,1.356-0.186,2.712-0.186,4.1C76.547,115.066,96.207,144.726,126.644,157.599z M89.561,78.819l0.077-1.157
        c2.262-36.344,32.545-64.808,68.947-64.808c38.15,0,69.178,31.029,69.211,68.587l-0.039,0.675
        c-0.026,20.579-9.203,39.956-25.238,53.266l-3.053,2.327c-0.681,0.553-1.369,1.105-2.224,1.697
        c-1.542,1.048-3.175,1.992-4.833,2.931l-0.919,0.559c-1.388,0.746-2.847,1.407-4.364,2.082l-1.703,0.823
        c-0.977,0.405-2.005,0.758-3.021,1.112l-2.121,0.765c-0.411,0.154-0.829,0.321-1.234,0.443c-0.79,0.244-1.6,0.431-2.404,0.63
        l-2.564,0.656c-0.553,0.148-1.099,0.302-1.587,0.405c-0.855,0.167-1.729,0.283-2.596,0.405l-2.442,0.366
        c-0.591,0.103-1.183,0.206-1.845,0.27c-2.14,0.225-4.293,0.347-6.478,0.366l-0.604-0.006h-0.013
        c-7.037-0.006-13.946-1.054-20.553-3.123c-0.572-0.18-1.131-0.405-1.69-0.617l-2.416-0.88c-0.752-0.264-1.497-0.521-2.217-0.823
        C105.982,134.919,89.4,109.892,89.4,82.032C89.4,80.946,89.484,79.879,89.561,78.819z"
        />
      </g>
    </svg>
  );
};

export default ProfileIcon;
