import React from "react";
import { reduxForm } from "redux-form";
import BusinessQuestion from "./Question";
import ContinueButton from "_shared/Continue/Button";
import SkipStep from "_shared/SkipStep";
import "Business.scss";

let Business = (props) => {
	const { handleSubmit, match, invalid, saving } = props;
  const question = match.params.property;
  const continueButton =
    match.params.property === "based" ? null : (
      <ContinueButton invalid={invalid} saving={saving} />
    );
  const skipText = (question === 'ein' || question === 'website') ? "Doesn't have one" : null;

  return (
    <div className="app-section slide">
      <form onSubmit={handleSubmit}>
        <BusinessQuestion
          theme={props.theme}
          question={question}
          history={props.history}
          change={props.change}
          invalid={invalid}
          handleSubmit={handleSubmit}
          nextRoute={props.nextRoute}
        />

        {continueButton}

        <SkipStep
          skipText={skipText}
          invalid={invalid}
          nextRoute={props.nextRoute}
        />
      </form>
    </div>
  );
};

Business = reduxForm({
	form: "application",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(Business);

export default Business;
