import React from "react";

const OwnershipIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Ownership Icon</title>
      <path
        fill={props.fill}
        d="M24.56,0A24.56,24.56,0,1,0,49.11,24.56,24.59,24.59,0,0,0,24.56,0Zm0,47.78A23.23,23.23,0,1,1,47.78,24.56,23.25,23.25,0,0,1,24.56,47.78Z"
      />
      <path
        fill={props.fill}
        d="M24.48,39.37a16.2,16.2,0,0,1-12.86-6.49,1.08,1.08,0,0,1-.19-.93,12.35,12.35,0,0,1,1.83-5,5.51,5.51,0,0,1,3.53-2.37.57.57,0,0,1,.58.19,9.64,9.64,0,0,0,5.46,2.89A9.79,9.79,0,0,0,31,25.47a3.14,3.14,0,0,0,.6-.55.81.81,0,0,1,1-.26,5.83,5.83,0,0,1,3.64,2.94,13.72,13.72,0,0,1,1.46,4.54.87.87,0,0,1-.21.71,16.06,16.06,0,0,1-10.36,6.28,8.7,8.7,0,0,1-.87.13C25.68,39.31,25.1,39.34,24.48,39.37Z"
      />
      <path
        fill={props.fill}
        d="M16.47,17.83a8.09,8.09,0,1,1,8.06,8.11A8.1,8.1,0,0,1,16.47,17.83Z"
      />
    </svg>
  );
};

export default OwnershipIcon;
