import React, { Component } from "react";
import HomeBasedIcon from "_icons/HomeBasedIcon";

class HomeBased extends Component {
	setHomeBasedYes = () => {
		this.props.change("home_based", "yes");
		this.props.nextRoute();
	};

	setHomeBasedNo = () => {
		this.props.change("home_based", "no");
		this.props.nextRoute();
	};

	render() {
		const { theme } = this.props;
		return (
			<React.Fragment>
				<HomeBasedIcon fill={theme.primary} />
				<h3>Is the business home based?</h3>

				<div className="home-based-button-wrap">
					<button
						type="button"
						className="btn btn-home-based-yes"
						onClick={this.setHomeBasedYes}
					>
						Yes
					</button>

					<button
						type="button"
						className="btn-secondary btn-home-based-no"
						onClick={this.setHomeBasedNo}
					>
						No
					</button>
				</div>
			</React.Fragment>
		);
	}
}

export default HomeBased;
