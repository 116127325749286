import axios from "axios";
import * as Sentry from "@sentry/browser";

const call = async (route, data, user = {}) => {
	const headers =
		user && user.token ? { Authorization: "Bearer " + user.token } : {};

	// add site identifier to all post requests
	data.siteId = VENDOR_NAME;
	const apiUrl = API_URL || "";
	return await axios
		.post(`${apiUrl}/api/portal/${route}`, data, { headers: headers })
		.then(function (response) {
			let result = Object.assign({}, response.data);

			if (!response.data) {
				result.msg = "response.data was empty";
			}

			if (result.error) {
				Sentry.captureException(
					new Error(`Code 1. serviceApi.call result error. [${result.msg}]`)
				);
			}

			return result;
		})
		.catch((error) => {
			Sentry.withScope((scope) => {
				if (error.response) {
					if (error.response.status === 422) {
						scope.setLevel("warning");
					}

					if (error.response.data && error.response.data.errors) {
						scope.setExtra("errors", error.response.data.errors);
					}

					scope.setExtra("status", error.response.status);
					scope.setExtra("statusText", error.response.statusText);
				}
				// using `error` instead of error.resposne will collapse error object into string
				Sentry.captureException(new Error(error));
			});

			return {
				error: true,
				level: "warning",
				feedback: true,
				msg: error.response
					? error.response.statusText
					: "Unknown - Possible Network Error",
			};
		});
};

export const apiService = {
	call,
};
