import React from "react";

const BackArrowIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 22 19"
      aria-labelledby="title"
    >
      <title id="title">Back Arrow Icon</title>
      <path
        fill={props.fill}
        d="M21.79,9.33a1,1,0,0,1-1,1H4.17l7.49,6.58a1,1,0,0,1,.09,1.41,1,1,0,0,1-.75.34,1,1,0,0,1-.66-.24L0,9.33,10.34.25a1,1,0,0,1,1.41.09,1,1,0,0,1-.09,1.41L4.17,8.33H20.79A1,1,0,0,1,21.79,9.33Z"
      />
    </svg>
  );
};

export default BackArrowIcon;
