import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import numeral from "numeral";

const LoanBox = (props) => {
	const { loan_amount, loan_id, loan_date, loan_type, loan_stage } = props;

	const loan = loan_type === "eq" ? "Equipment Financing" : "Working Capital";
	const amount = numeral(loan_amount).format("0,0");
	const today = new Date(Date.now()).toLocaleString().split(", ")[0];
	const date = !loan_date ? today : loan_date;

	let status;
	let nextLink;
	let nextText;

	switch (loan_stage) {
		case "Apply. Started":
			status = "Application incomplete";
			nextLink = "/app/business/years";
			nextText = "Continue";
			break;

		case "Awaiting Statements":
			status = "Awaiting bank statements";
			nextLink = "/app/bank/connect";
			nextText = "Connect Bank";
			break;
	}

	if (loan_id) {
		return (
			<div className="app-info-box">
				<div className="title">{loan} Application</div>
				<p>
					Requested Amount: <strong>${amount}</strong>
				</p>
				<p>
					Last Touched: <strong>{date}</strong>
				</p>
				<p>
					Status: <strong>{status}</strong>
				</p>
				<p>
					Next Step: <Link to={nextLink}>{nextText}</Link>
				</p>
			</div>
		);
	} else {
		return (
			<React.Fragment>
				You do not have any unfinished applications. You can start a new one
				though!
				<div className="group ctaLinks">
					<Link to="/app/loan/eq">
						<button className="btn btn-home">
							Equipment
							<br />
							Loans
						</button>
					</Link>
					<Link to="/app/loan/wc">
						<button className="btn btn-home">
							Working
							<br />
							Capital
						</button>
					</Link>
				</div>
			</React.Fragment>
		);
	}
};

const selector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		loan_amount: selector(state, "loan_amount"),
		loan_id: selector(state, "loan_id"),
		loan_date: selector(state, "loan_date"),
		loan_type: selector(state, "loan_type"),
		loan_stage: selector(state, "loan_stage"),
	};
};

export default connect(mapStateToProps)(LoanBox);
