import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

export const Wrapper = (props) => {
	return <div className={classNames(...props.classes)}>{props.children}</div>;
};

export const Label = (props) => {
	if (props.text) {
		return <label htmlFor={props.name}>{props.text}</label>;
	} else return null;
};

export const Error = (props) => {
	const {
		meta: { error, touched, active },
		hide,
	} = props;

	if (error && touched && !active && !hide) {
		return <div className="error-text">{error}</div>;
	} else return null;
};

export class customInput extends Component {
	render() {
		const {
			meta,
			labelText,
			input,
			className,
			type,
			focusRef,
			placeholder,
			autoFocus,
			tabIndex,
			hideError,
		} = this.props;

		return (
			<Wrapper classes={["app-input", validityClass(meta)]}>
				<Label text={labelText} name={input.name} />
				<Wrapper classes={["wrap-input", className]}>
					<input
						{...input}
						id={input.name}
						type={type}
						ref={focusRef}
						placeholder={placeholder}
						autoFocus={autoFocus}
						tabIndex={tabIndex}
						data-hj-whitelist
					/>
					<Error meta={meta} hide={hideError} />
				</Wrapper>
			</Wrapper>
		);
	}
}

export class basicInput extends Component {
	render() {
		const {
			meta,
			labelText,
			input,
			className,
			type,
			focusRef,
			placeholder,
			autoFocus,
			tabIndex,
			hideError,
		} = this.props;

		return (
			<React.Fragment>
				<input
					{...input}
					id={input.name}
					type={type}
					ref={focusRef}
					placeholder={placeholder}
					autoFocus={autoFocus}
					tabIndex={tabIndex}
					data-hj-whitelist
				/>
				<Error meta={meta} hide={hideError} />
			</React.Fragment>
		);
	}
}

export const rangeInput = (props) => {
	const { input, min, max } = props;

	return (
		<input
			{...input}
			id={input.name}
			type="range"
			min={min}
			max={max}
			step="1000"
		/>
	);
};

export const radioButton = (props) => {
	const { type, input, id } = props;

	return <input {...input} id={id} type={type} />;
};

export const checkBox = (props) => {
	const { type, input, id } = props;

	return <input {...input} id={id} type={type} />;
};

let muiInputBase = (props) => {
	const {
		input,
		type,
		label,
		meta,
		classes,
		className,
		autoFocus,
		tabIndex,
	} = props;

	const inputClasses = classNames(
		className,
		"mui-input-custom",
		validityClass(meta)
	);

	return (
		<div className="inline">
			<TextField
				{...input}
				type={type}
				className={inputClasses}
				InputLabelProps={{
					classes: {
						root: classes.cssLabel,
						focused: classes.cssFocused,
					},
				}}
				label={label}
				autoFocus={autoFocus}
				InputProps={{
					inputProps: { tabIndex: tabIndex, "data-hj-whitelist": "" },
				}}
			/>
			<Error meta={meta} />
		</div>
	);
};

const styles = () => ({
	cssLabel: {
		"&$cssFocused": {
			color: window.themePrimary || "#999",
		},
	},
	cssFocused: {},
});

export const muiInput = withStyles(styles)(muiInputBase);

const validityClass = (meta) => {
	if (meta.active) {
		return;
	}
	if (meta.touched && meta.invalid) {
		return "invalid";
	}
	if (meta.touched && meta.valid) {
		return "valid";
	}
};

import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";

export class datePicker extends Component {
	render() {
		const { dateValue, handleDateChange, meta, hideError } = this.props;

		return (
			<Wrapper classes={[validityClass(meta)]}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						value={dateValue}
						onChange={handleDateChange}
						className="mui-date-picker"
						format="MM/dd/yyyy"
						openTo="year"
						maxDate={new Date()}
						autoOk
						autoFocus
					/>
				</MuiPickersUtilsProvider>
			</Wrapper>
		);
	}
}

export const muiSelect = (props) => {
	const { input, label, className } = props;
	return (
		<FormControl variant="filled" className={className}>
			<InputLabel shrink>{label}</InputLabel>
			<Select {...input} label={label}>
				{props.children}
			</Select>
		</FormControl>
	);
};

export const muiText = (props) => {
	const { input, type, className, label } = props;
	return (
		<TextField
			{...input}
			variant="filled"
			type={type}
			className={className}
			label={label}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);
};
