import React from "react";

const MoneyIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Money Icon</title>
      <path
        fill={props.fill}
        d="M24.56,49.11A24.56,24.56,0,1,1,49.11,24.56,24.58,24.58,0,0,1,24.56,49.11Zm0-47.78A23.23,23.23,0,1,0,47.78,24.56,23.26,23.26,0,0,0,24.56,1.33Zm2.76,28a2.85,2.85,0,0,0-.65-2A6.09,6.09,0,0,0,24.39,26c-1.09-.43-2.1-.87-3-1.31A10.66,10.66,0,0,1,19,23.12a6,6,0,0,1-1.53-2,6.46,6.46,0,0,1-.53-2.77,5.87,5.87,0,0,1,1.9-4.5,8.34,8.34,0,0,1,5.07-2.06V8.37h2.65v3.47a7.54,7.54,0,0,1,4.8,2.43,7.41,7.41,0,0,1,1.74,5.06H27.44a4.29,4.29,0,0,0-.67-2.59,2.48,2.48,0,0,0-3.61-.23,2.68,2.68,0,0,0-.61,1.85,2.58,2.58,0,0,0,.7,1.89,6.84,6.84,0,0,0,2.26,1.34c1,.43,2,.87,2.92,1.33a11,11,0,0,1,2.38,1.55,6.34,6.34,0,0,1,1.57,2A6.25,6.25,0,0,1,33,29.3a6,6,0,0,1-1.81,4.5,7.92,7.92,0,0,1-4.93,2v3.25H23.55V35.82A8.65,8.65,0,0,1,18,33.41,7.53,7.53,0,0,1,16,28h5.62a4.08,4.08,0,0,0,.85,2.8,3,3,0,0,0,2.39,1,2.36,2.36,0,0,0,1.78-.65A2.43,2.43,0,0,0,27.32,29.33Z"
      />
    </svg>
  );
};

export default MoneyIcon;
