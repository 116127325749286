import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { store, persistor } from "redux/store";

const User = lazy(() => import("./components/User"));
const Portal = lazy(() => import("./components/Portal"));

import ErrorBoundary from "./components/_shared/Error/Error";

Sentry.init({
	dsn: SENTRY_DSN,
	environment: SENTRY_ENV,
	integrations: [new BrowserTracing(), new Sentry.Replay()],
	tracesSampleRate: 0.5,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

const App = () => {
	const userRouteDefault = "/user/:action";
	const appRouteDefault = "/app/:step/:property?";
	const appRouteIndexed = "/app/:step/:idx/:property";

	return (
		<Router>
			<ErrorBoundary>
				<Switch>
					<Suspense fallback={<div>Loading...</div>}>
						<Route exact path="/" component={User} />
						<Route exact path={userRouteDefault} component={User} />
						<Route exact path={appRouteDefault} component={Portal} />
						<Route exact path={appRouteIndexed} component={Portal} />
					</Suspense>
				</Switch>
			</ErrorBoundary>
		</Router>
	);
};

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
