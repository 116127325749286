import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import CloseIcon from "_icons/CloseIcon";

const Frozen = props => {
  return (
    <div className="app-section slide">
      <div className="form-width decision">
        <CloseIcon fill={props.theme.primary} width="22" height="22" />
        <h3>Credit Frozen</h3>

        <div className="left">
          <p>{props.first},</p>
          <p>
            It appears you have placed a security freeze or lock on your credit
            report. Please contact Equifax to remove it so that we can continue.
          </p>
          <p>
            Equifax states that it can take 24-48 hours to remove a credit
            freeze. Credit locks can sometimes be lifted immediately.
          </p>
          <p>You can return to this application afterwards and resubmit.</p>
        </div>

        <Link to="/app/owners/1/info">
          <button className="btn">Return to App</button>
        </Link>
      </div>
    </div>
  );
};

const selector = formValueSelector("application");

const mapStateToProps = state => {
  return {
    first: selector(state, "owner_1_first")
  };
};

export default connect(mapStateToProps)(Frozen);
