import React from "react";

const EinIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Ein Icon</title>
      <path
        fill={props.fill}
        d="M24.56,49.11A24.56,24.56,0,1,1,49.11,24.56,24.58,24.58,0,0,1,24.56,49.11Zm0-47.78A23.23,23.23,0,1,0,47.78,24.56,23.26,23.26,0,0,0,24.56,1.33ZM35.48,32.57l-6-4.74A9.27,9.27,0,1,0,28,29.47l6.08,4.82a1.14,1.14,0,0,0,.68.23,1.09,1.09,0,0,0,.68-2ZM21.89,29.68a7,7,0,1,1,7-6.95A7,7,0,0,1,21.89,29.68Z"
      />
    </svg>
  );
};

export default EinIcon;
