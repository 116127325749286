import React from "react";
import "./Loan.scss";

const Loan = props => {
  return (
    <div className="app-section slide loan-btns">
				<h3>Submit a customer application for:</h3>
				<button className="btn btn-home" onClick={props.setEq}>
					Equipment
					<br />
					Loans
				</button>

				<button className="btn btn-home" onClick={props.setWc}>
					Working
					<br />
					Capital
				</button>
			</div>
  )
}

export default Loan;