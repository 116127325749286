import React from "react";
import { Link } from "react-router-dom";
import CloseIcon from "_icons/CloseIcon";

const Decline = props => {
  return (
    <div className="app-section slide">
      <div className="form-width">
        <CloseIcon fill={props.theme.primary} width="22" height="22" />
        <h3>Declined</h3>
        <h5>
          Unfortunately, we are unable to approve this request for a loan.
        </h5>

        <div className="left">
          <strong>Some reasons why we may decline an applicant include:</strong>
          <ul>
            <li>
              Insufficient time in business for the product or amount requested
            </li>
            <li>Low FICO score</li>
            <li>Derogatory personal/business credit</li>
            <li>Inability to obtain a credit report</li>
            <li>Unable to verify identity</li>
          </ul>
        </div>

        <Link to="/user/dashboard"><button className="btn btn-dashboard">Back to Dashboard</button></Link>

      </div>
    </div>
  );
};

export default Decline;
