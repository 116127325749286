import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { handleSignOut } from "utils/reduxUtils";

let Signout = (props) => {
	handleSignOut(props.dispatch);

	return (
		<div className="app-section">
			<h3>Thank You!</h3>

			<p>
				You are now signed out. We have automatically cleared sensitive data
				from your browser.
			</p>

			<p>
				If for any reason you need to sign back in you can do so here.{" "}
				<Link to="/">Sign in</Link>
			</p>
		</div>
	);
};

Signout = reduxForm({
	form: "login",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(Signout);

export default connect()(Signout);
