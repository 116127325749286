import React from "react";

const IdentityIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Identity Icon</title>
      <path
        fill={props.fill}
        d="M24.56,0A24.56,24.56,0,1,0,49.11,24.56,24.59,24.59,0,0,0,24.56,0Zm0,47.78A23.23,23.23,0,1,1,47.78,24.56,23.25,23.25,0,0,1,24.56,47.78Z"
      />
      <path
        fill={props.fill}
        d="M36.92,19.44H19.74a1.21,1.21,0,0,1,0-2.41H36.92a1.21,1.21,0,0,1,0,2.41Z"
      />
      <path
        fill={props.fill}
        d="M13.54,20.33,11.16,17.7a.67.67,0,1,1,1-.89l1.31,1.44,2.86-3.67a.67.67,0,0,1,.94-.11.66.66,0,0,1,.11.93Z"
      />
      <path
        fill={props.fill}
        d="M36.92,27H19.74a1.21,1.21,0,0,1,0-2.41H36.92a1.21,1.21,0,0,1,0,2.41Z"
      />
      <path
        fill={props.fill}
        d="M13.54,27.87l-2.38-2.62a.67.67,0,1,1,1-.9l1.31,1.44,2.86-3.66a.67.67,0,1,1,1.05.82Z"
      />
      <path
        fill={props.fill}
        d="M36.92,34.52H19.74a1.2,1.2,0,1,1,0-2.4H36.92a1.2,1.2,0,0,1,0,2.4Z"
      />
      <path
        fill={props.fill}
        d="M13.54,35.41l-2.38-2.62a.66.66,0,0,1,.05-.94.66.66,0,0,1,.94,0l1.31,1.45,2.86-3.67a.68.68,0,0,1,.94-.12.67.67,0,0,1,.11.94Z"
      />
    </svg>
  );
};

export default IdentityIcon;
