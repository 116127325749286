import React from "react";

const CalendarIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Calendar Icon</title>
      <path
        fill={props.fill}
        d="M24.56 49.11a24.56 24.56 0 1 1 24.55-24.55 24.58 24.58 0 0 1-24.55 24.55zm0-47.78a23.23 23.23 0 1 0 23.22 23.23A23.26 23.26 0 0 0 24.56 1.33zm-6.69 9.32a1.52 1.52 0 0 0-1.42-1.6 1.52 1.52 0 0 0-1.45 1.6v2.15a1.52 1.52 0 0 0 1.41 1.6 1.52 1.52 0 0 0 1.42-1.6zm19.53 1.58h-2.84v1a1.7 1.7 0 0 1-1.7 1.7h-.4a1.7 1.7 0 0 1-1.7-1.7v-1H18.35v1a1.7 1.7 0 0 1-1.7 1.7h-.4a1.7 1.7 0 0 1-1.7-1.7v-1h-2.84a1.6 1.6 0 0 0-1.6 1.59v21.47a1.6 1.6 0 0 0 1.6 1.6H37.4a1.6 1.6 0 0 0 1.6-1.6V13.82a1.6 1.6 0 0 0-1.6-1.59zm.53 23.06a.53.53 0 0 1-.53.53H11.71a.53.53 0 0 1-.53-.53V20.1h26.75zm-3.86-24.64a1.42 1.42 0 1 0-2.82 0v2.15a1.42 1.42 0 1 0 2.82 0zm-7.6 10.68h-3.83v3.51h3.83zm5.13 0h-3.82v3.51h3.82zm5.14 0h-3.83v3.51h3.83zM16.2 26.07h-3.83v3.51h3.83zm5.13 0h-3.82v3.51h3.82zm5.14 0h-3.83v3.51h3.83zm5.13 0h-3.82v3.51h3.82zm5.14 0h-3.83v3.51h3.83zM16.2 30.82h-3.83v3.5h3.83z"
      />
    </svg>
  );
};

export default CalendarIcon;
