import React, { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import { withRouter } from "react-router-dom";
import Loan from "./Loan";

class LoanContainer extends Component {
	constructor(props) {
		super();

		this.selectLoanType = this.selectLoanType.bind(this);
	}

	componentDidMount() {
		const { type } = this.props.match.params;

		if (type === "eq" || type === "wc") {
			this.selectLoanType(type);
		}
	}

	selectLoanType(type) {
		const { dispatch, config } = this.props;

		dispatch(change("application", "loan_type", type));
		dispatch(change("application", "loan_terms", config[type].selectedTerm));
		dispatch(change("application", "loan_amount", config[type].amount));

		this.props.nextRoute();
	}

	setEq = () => {
		this.selectLoanType("eq");
	};

	setWc = () => {
		this.selectLoanType("wc");
	};

	render() {
		return <Loan setEq={this.setEq} setWc={this.setWc} />;
	}
}

const mapStateToProps = (state) => {
	return {
		config: state.config,
	};
};

export default connect(mapStateToProps)(withRouter(LoanContainer));
