import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";

const DashboardItem = (props) => {
	return (
		<MenuItem onClick={props.handleClose}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText inset primary="Dashboard" />
		</MenuItem>
	);
};

export default DashboardItem;
