import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import VerifyForm from "_shared/Verify/Form";

let Verify = (props) => {
	return (
		<div className="app-section">
			<VerifyForm form="login" {...props} />
		</div>
	);
};

const selector = formValueSelector("login");

const mapStateToProps = (state) => {
	return {
		mobile: selector(state, "mobile"),
		verifyMethod: "text",
		temp: state.temp,
	};
};

Verify = reduxForm({
	form: "login",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(Verify);

export default connect(mapStateToProps)(Verify);
