export const required = value => (value ? undefined : "Required");

export const emailAddress = value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? "Invalid"
		: undefined;

export const phoneNumber = value =>
	value && !/^\d{3}-\d{3}-\d{4}$/i.test(value)
		? "Invalid, must be 10 digits"
		: undefined;

export const fedTaxId = value =>
	value && !/^\d{2}-\d{7}$/i.test(value)
		? "Invalid, must be xx-xxxxxxx format"
		: undefined;

export const dob = value =>
	value && !/^\d{2}\/\d{2}\/\d{4}$/i.test(value)
		? "Invalid, must be mm/dd/yyyy format"
		: undefined;

export const ssn = value =>
	value && !/^\d{3}-\d{2}-\d{4}$/i.test(value)
		? "Invalid, must be xxx-xx-xxxx format"
		: undefined;
