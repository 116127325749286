import React from "react";

const SearchIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <path
        fill={props.fill}
        d="M22,19.89l-5.67-5.15a9,9,0,0,0,1.9-5,9.16,9.16,0,1,0-3.44,6.53l5.74,5.21a1,1,0,0,0,.66.28,1,1,0,0,0,.88-.35A1.09,1.09,0,0,0,22,19.89ZM8.66,16.08a7,7,0,1,1,7.42-6.47A7,7,0,0,1,8.66,16.08Z"
      />
    </svg>
  );
};

export default SearchIcon;
