import React from 'react';

const CustomItem = props => {
  const { street, city, state } = props;

  return (
    <div className="custom-suggest-item">
      <div className="street">{street}</div>
      <div className="city-state">{city}, {state}</div>
    </div>
  );
}

export default CustomItem;