import axios from "axios";
import { change } from "redux-form";

export const SET_THEME = "SET_THEME";
export const CLEAR_THEME = "CLEAR_THEME";
export const SET_USER = "SET_USER";
export const AUTH_FAILED = "AUTH_FAILED";
export const LOGOUT = "LOGOUT";
export const SET_TEMP = "SET_TEMP";
export const CLEAR_TEMP = "CLEAR_TEMP";
export const SET_DATA = "SET_DATA";
export const CLEAR_DATA = "CLEAR_DATA";

export const SET_CONFIG = "SET_CONFIG";
export const SET_VALIDATON_TYPE = 'SET_VALIDATON_TYPE';
export const LOAD_VENDOR_STARTED = "LOAD_VENDOR_STARTED";
export const LOAD_VENDOR_SUCCESS = "LOAD_VENDOR_SUCCESS";
export const LOAD_VENDOR_FAILURE = "LOAD_VENDOR_FAILURE";
export const LOAD_VENDOR_DEFAULT = "LOAD_VENDOR_DEFAULT";

export function setTheme(theme) {
	// ohhhhh man this is ugly....could not get mui-input to use theme color
	window.themePrimary = theme.primary;
	return {
		type: SET_THEME,
		theme: theme,
	};
}

export function clearTheme() {
	return {
		type: CLEAR_THEME,
	};
}

export const loadVendor = (vendor) => {
	return (dispatch, getState) => {
		dispatch(loadVendorStarted());

		const apiUrl = API_URL || "";

		axios
			.post(`${apiUrl}/api/portal/vendor/config`, { vendor })
			.then(function (res) {
				dispatch(loadVendorSuccess(res.data));
				const state = getState();
				const config = {
					customVendor: true,
					...res.data,
				};
				updateVendorFormValues(state, config, dispatch);
			})
			.catch((err) => {
				dispatch(loadVendorFailure(err.message));
			});
	};
};

const loadVendorSuccess = (config) => ({
	type: LOAD_VENDOR_SUCCESS,
	payload: {
		...config,
	},
});

const loadVendorStarted = () => ({
	type: LOAD_VENDOR_STARTED,
});

const loadVendorFailure = (error) => ({
	type: LOAD_VENDOR_FAILURE,
	payload: {
		error,
	},
});

const updateVendorFormValues = (state, config, dispatch) => {
	const configAmount = config.eq.amount;
	const configTerms = config.eq.selectedTerm;

	if (state.form.application && state.form.application.values) {
		const loanAmount = state.form.application.values.loan_amount;
		const loanTerms = state.form.application.values.loan_terms;

		// if redux has some values but amount or terms are NOT set, go ahead and set from config
		if (!loanAmount) {
			dispatch(change("application", "loan_amount", configAmount));
		}
		if (!loanTerms) {
			dispatch(change("application", "loan_terms", configTerms));
		}

		// if custom config and the redux values match the default, override them
		if (config.customVendor) {
			if (loanAmount === defaultVendor.eq.amount) {
				dispatch(change("application", "loan_amount", configAmount));
			}
			if (loanTerms === defaultVendor.eq.selectedTerm) {
				dispatch(change("application", "loan_terms", configTerms));
			}
		}
	} else {
		// if state.form.application is empty we definitely need to set
		dispatch(change("application", "loan_amount", configAmount));
		dispatch(change("application", "loan_terms", configTerms));
	}
};

const defaultVendor = {
	id: VENDOR_ID,
	logo: "/img/logo.png",
	loaded: true, // default to true, used for custom vendor loads
	customVendor: false,
	eq: {
		rate: 8,
		min: 5000,
		max: 150000,
		amount: "100000",
		termOptions: [24, 36, 48, 60],
		selectedTerm: "60",
	},
	wc: {
		rate: 10,
		min: 5000,
		max: 250000,
		amount: "150000",
		termOptions: [6, 12, 24],
		selectedTerm: "6",
	},
};

export const loadDefaultVendor = (force = false) => {
	return (dispatch, getState) => {
		const state = getState();
		if (!state.config.customVendor || force) {
			dispatch(loadVendorDefault(defaultVendor));
			updateVendorFormValues(state, defaultVendor, dispatch);
		}
	};
};

const loadVendorDefault = (config) => ({
	type: LOAD_VENDOR_DEFAULT,
	payload: {
		...config,
	},
});

export function setConfig(config) {
	return {
		type: SET_CONFIG,
		config: config,
	};
}

export function clearConfig() {
	return {
		type: CLEAR_CONFIG,
	};
}

export function setValidationType(type) {
	return {
	  type: SET_VALIDATON_TYPE,
	  signInType: { type },
	};
  }  

export function setUser(data) {
	if (data.user && data.user.token) {
		return {
			type: SET_USER,
			user: data.user,
		};
	}

	return {
		type: AUTH_FAILED,
	};
}

export function logout() {
	return {
		type: LOGOUT,
	};
}

export function setTemp(data) {
	return {
		type: SET_TEMP,
		data,
	};
}

export function clearTemp() {
	return {
		type: CLEAR_TEMP,
	};
}

export function setData(data) {
	return {
		type: SET_DATA,
		deals: data.deals,
	};
}

export function clearData() {
	return {
		type: CLEAR_DATA,
	};
}
