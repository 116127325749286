import React from "react";
import { reduxForm, Field } from "redux-form";
import { muiInput } from "_fields/inputs";
import { phone, lowerCase, words } from "_fields/normalizers";
import { required, phoneNumber, emailAddress } from "_fields/validation";
import ContinueButton from "_shared/Continue/Button";

let Info = (props) => {
	const { handleSubmit, invalid, saving } = props;

  return (
    <div className="app-section slide">
      <form onSubmit={handleSubmit}>
        <h3>Let's start with some basic info:</h3>

        <Field
          name="business_name"
          component={muiInput}
          type="text"
          label="Business Name"
          className="input-100"
          validate={[required]}
          normalize={words}
          autoFocus
        />

        <Field
          name="owner_1_first"
          component={muiInput}
          type="text"
          label="First Name"
          className="input-50 mr"
          validate={[required]}
          normalize={words}
        />

        <Field
          name="owner_1_last"
          component={muiInput}
          type="text"
          label="Last Name"
          className="input-50"
          validate={[required]}
          normalize={words}
        />

        <Field
          name="owner_1_mobile"
          component={muiInput}
          type="tel"
          label="Mobile Phone"
          className="input-100"
          normalize={phone}
          validate={[required, phoneNumber]}
        />

        <Field
          name="owner_1_email"
          component={muiInput}
          type="email"
          label="Email"
          className="input-100"
          normalize={lowerCase}
          validate={[required, emailAddress]}
        />

        <ContinueButton invalid={invalid} saving={saving} showLock />
      </form>
    </div>
  );
};

Info = reduxForm({
	form: "application",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(Info);

export default Info;
