import React from "react";

const UploadIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
    >
      <title id="title">Upload Icon</title>
      <g transform="translate(-1351 -16408)">
        <path
          fill={props.fill}
          d="M307.2,468.438a24.556,24.556,0,1,0,24.555,24.556A24.584,24.584,0,0,0,307.2,468.438Zm0,47.781a23.225,23.225,0,1,1,23.225-23.225A23.252,23.252,0,0,1,307.2,516.219Z"
          transform="translate(1068.352 15939.563)"
        />
        <g transform="translate(1068.352 15939.563)">
          <path
            fill={props.fill}
            d="M318.781,486.544l-.01-.048c-.021-.051-4.56-5.076-5.177-5.076H298.083a.369.369,0,0,0-.277.1.405.405,0,0,0-.09.31V504.06a1.493,1.493,0,0,0,1.71,1.444h.038s2.186.006,3.35,0a.232.232,0,0,0,.232-.232v-1.387a.234.234,0,0,0-.233-.233l-3.127.018L299.67,483.3l12.416-.017v.64c0,.691,0,1.367,0,2.043a2.01,2.01,0,0,0,2.1,2.084c.658,0,1.314,0,1.977,0l.651-.018.017,15.672h-2.877a.233.233,0,0,0-.232.232v1.386a.234.234,0,0,0,.233.233c1.02-.005,3.137.006,3.138.006a1.536,1.536,0,0,0,1.7-1.326h0C318.792,504.206,318.792,503.591,318.781,486.544Zm-2.823-.432-1.925.018v-2.057Z"
          />
          <path
            fill={props.fill}
            d="M303.692,501.365h.28c.792,0,1.229.008,1.653.016.377.006.745.013,1.339.014,0,.657,0,1.3-.007,1.945-.005.911-.01,1.811-.006,2.711v.23c.005,1.575.057,1.638,1.409,1.66h.045c1.233-.022,1.231-.143,1.215-1.621l0-.171c-.006-.536,0-1.016,0-1.514v-3.292c1.253,0,1.631,0,2.854,0h.275l-4.439-5.106Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default UploadIcon;
