import React, { Component } from "react";
import { Field } from "redux-form";
import classNames from "classnames";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { customInput } from "_fields/inputs";
import { normFedTaxId } from "_fields/normalizers";
import { required, fedTaxId } from "_fields/validation";
import FindIcon from "_icons/FindIcon";
import CloseIcon from "_icons/CloseIcon";

class FedTaxId extends Component {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);

    this.state = {
      open: false
    };

    this.einInputRef = React.createRef();
  }

  toggleModal() {
    // refocus ein field
    if (!this.state.open) {
      this.einInputRef.current.focus();
    }

    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { classes, theme, invalid } = this.props;

    let linkClass = classNames({
      "faux-link": true,
      muted: invalid ? false : true,
    });

    return (
      <React.Fragment>
        <FindIcon fill={theme.primary} />
        <h3>Federal Tax ID</h3>

        <Field
          name="business_ein"
          component={customInput}
          type="tel"
          placeholder="XX-XXXXXXX"
          normalize={normFedTaxId}
          validate={[required, fedTaxId]}
          focusRef={this.einInputRef}
          autoFocus
        />

        <div className={linkClass} onClick={this.toggleModal}>
          Where do I find this?
        </div>

        <Modal
          aria-labelledby="find-federal-tax-id-title"
          aria-describedby="find-federal-tax-id-option1"
          open={this.state.open}
          onClose={this.toggleModal}
          className="mui-modal"
        >
          <div style={getModalStyle()} className={classes.paper}>
            <div className="modal-close-icon" onClick={this.toggleModal}>
              <CloseIcon fill="#ffffff" width="30" height="30" />
            </div>
            <Typography variant="h6" id="find-federal-tax-id-title">
              If you lost or misplaced your EIN, you can retrieve it in the
              following ways:
            </Typography>
            <Typography variant="body1" id="find-federal-tax-id-option1">
              Reference the original notice issued by the IRS when you received
              your EIN or call the IRS Business &amp; Specialty Tax Line at{" "}
              <a href="tel:+18008294933">1-800-829-4933</a>.
            </Typography>
            <Typography variant="body1" id="find-federal-tax-id-option2">
              If you used it to open a bank account or get a license, contact
              these organizations they should be able to retrieve your number.
            </Typography>
            <Typography variant="body1" id="find-federal-tax-id-option3">
              Find an old tax return. Your EIN should be on it.
            </Typography>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: "300px",
    backgroundColor: "#fff",
    padding: "30px",
    outline: "none",
    borderRadius: "15px"
  }
});

export default withStyles(styles)(FedTaxId);
