import React, { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import { withRouter } from "react-router-dom";
import Owners from "./Owners";
import "Owners.scss";

class OwnersContainer extends Component {
	constructor(props) {
		super();

		this.saveOwner = this.saveOwner.bind(this);
	}

	async saveOwner(form) {
		const { idx, property } = this.props.match.params;

		const post = {
			route: property,
			contact_id: form[`owner_${idx}_id`],
			main: Number(idx) === 1 ? true : false,
			ownerIdx: idx,
			type: this.props.user.type,
		};

		switch (post.route) {
			case "info":
				post.first = form[`owner_${idx}_first`];
				post.last = form[`owner_${idx}_last`];
				post.mobile = form[`owner_${idx}_mobile`];
				post.email = form[`owner_${idx}_email`];
				break;

			case "ownership":
				post.title = form[`owner_${idx}_title`];
				post.ownership = form[`owner_${idx}_ownership`];
				if (idx === "1") {
					post.home_based = form.home_based;
					post.address = {
						street: form.business_street,
						suite: form.business_suite,
						city: form.business_city,
						state: form.business_state,
						zip: form.business_zip,
					};
				}
				break;

			case "address":
				post.address = {
					street: form[`owner_${idx}_street`],
					suite: form[`owner_${idx}_suite`],
					city: form[`owner_${idx}_city`],
					state: form[`owner_${idx}_state`],
					zip: form[`owner_${idx}_zip`],
				};
				break;

			case "identity":
				post.ssn = form[`owner_${idx}_ssn`];
				post.dob = form[`owner_${idx}_dob`];
				// used to load credit decision and determine if we should skip credit check
				post.loan_type = form.loan_type;
				post.amount = form.loan_amount;
				break;

			case "add":
				this.props.nextRoute();
				return;
		}

		const response = await this.props.callApi(`owners/${post.route}`, post);

		if (!response.error) {
			// check for new owner id
			if (response.newOwnerId) {
				// compose field key
				const fieldKey = `owner_${this.props.match.params.idx}_id`;
				// set new owner id
				this.props.dispatch(
					change("application", fieldKey, response.newOwnerId)
				);
			}

			if (post.route === "identity") {
				// used to determine if we need to display credit range selection
				this.props.dispatch(
					change("application", "owner_1_fico", response.fico)
				);
			}

			this.props.nextRoute();
		}
	}

	render() {
		return <Owners onSubmit={this.saveOwner} {...this.props} />;
	}
}

const mapStateToProps = (state) => {
	return {
		theme: state.theme,
		user: state.user,
	};
};

export default connect(mapStateToProps)(withRouter(OwnersContainer));
