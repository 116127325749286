import React, { Component } from "react";
import { connect } from "react-redux";
import { destroy, reset } from "redux-form";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	root: {
		width: "100%",
		overflowX: "auto",
		overflowY: "auto",
	},
	table: {
		minWidth: 650,
	},
	fab: {
		position: "absolute",
		bottom: "25px",
		right: "25px",
		backgroundColor: "#47d7ac",
		"&:hover": {
			backgroundColor: "#31cda8",
		},
	},
});

class Dashboard extends Component {
	constructor(props) {
		super(props);
	}

	handleClick = () => {

		// clear any previous form data
		this.props.dispatch(destroy("application"));

		if (this.props.user.type === "vendor") {
			this.props.history.push("/app/loan/eq");
		} else if (this.props.user.type === "affiliate") {
			this.props.history.push("/app/loan");
		} else {
			console.log('User is not a vendor or affiliate, here are the props', this.props.user);
		}
	};

	getNoDealsMessage = (deals) => {
		return deals.length === 0 ? (
			<TableRow key="0">
				<TableCell colSpan="5">
					No active deals.{" "}
					<span className="faux-link" onClick={this.handleClick}>
						Add new deal
					</span>
				</TableCell>
			</TableRow>
		) : null;
	};

	render() {
		const { user, deals, classes } = this.props;
		const no_deals_message = this.getNoDealsMessage(deals);

		return (
			<div className="portal">
				<h3 className="applications">Applications</h3>
				<h4 className="welcome">Welcome back, {user.first}</h4>

				<Paper className={classes.root}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Business</TableCell>
								<TableCell align="right">Stage</TableCell>
								<TableCell align="right">Date</TableCell>
								<TableCell align="right">Amount</TableCell>
								<TableCell align="right">Terms</TableCell>
								<TableCell align="right">Type</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{deals.map((row, idx) => (
								<TableRow key={idx}>
									<TableCell component="th" scope="row">
										{row.business}
									</TableCell>
									<TableCell align="right">{row.loan_stage}</TableCell>
									<TableCell align="right">{row.loan_date}</TableCell>
									<TableCell align="right">{row.loan_amount}</TableCell>
									<TableCell align="right">{row.loan_terms}</TableCell>
									<TableCell align="right">{row.loan_type}</TableCell>
								</TableRow>
							))}
							{no_deals_message}
						</TableBody>
					</Table>
				</Paper>

				<Fab
					aria-label="New App"
					className={`${classes.fab} new-deal-btn`}
					color="primary"
					variant="extended"
					onClick={this.handleClick}
				>
					<AddIcon /> New Deal
				</Fab>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		deals: state.data.deals || [],
	};
};

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
