import React, { Component } from "react";
import Geosuggest from "react-geosuggest";
import ChevronIcon from "_icons/ChevronIcon";
import SearchIcon from "_icons/SearchIcon";
import CloseIcon from "_icons/CloseIcon";
import AddressManual from "./Manual";
import CustomItem from "./CustomItem";
import "Address.scss";

class Address extends Component {
	constructor(props) {
		super(props);

		this.toggleManualAddress = this.toggleManualAddress.bind(this);
		this.onSuggestSelect = this.onSuggestSelect.bind(this);
		this.onFocus = this.onFocus.bind(this);

		// determine the field prefix, if we have idx param, then we are on an owner, else it's the business
		const idx = props.ownerIdx || false;
		this.addressKey = idx ? `owner_${idx}` : `business`;
		this.addressType = idx ? "Home" : "Business";

		this.state = {
			manualAddress: false, //this.existingAddress(),
			addressFound: false,
			address: props.address,
		};

		this.clearSearch = this.clearSearch.bind(this);
	}

	componentDidMount() {
		if (!window.google) {
			this.setState({ manualAddress: true });
		}
		// set the input box if we've preloaded an address
		const { street, city, state } = this.props.address;
		let streetCheck = street || "";
		if (streetCheck.length > 0 && this._geoSuggest) {
			const preloadAddress = `${street}, ${city}, ${state}, USA`;
			this._geoSuggest.update(preloadAddress);
		}
	}

	onSuggestSelect(suggest) {
		if (!suggest.gmaps) {
			return;
		}

		const components = suggest.gmaps.address_components;

		const address = {
			street:
				this.findAddressComponent(components, "street_number", "short_name") +
				" " +
				this.findAddressComponent(components, "route", "short_name"),
			city: this.findAddressComponent(components, "locality", "short_name"),
			state: this.findAddressComponent(
				components,
				"administrative_area_level_1",
				"short_name"
			),
			zip: this.findAddressComponent(components, "postal_code", "short_name"),
		};

		this.setState({
			addressFound: 1,
			address,
		});

		// update redux-form store with address info
		this.props.change(`${this.addressKey}_street`, address.street);
		this.props.change(`${this.addressKey}_suite`, address.suite);
		this.props.change(`${this.addressKey}_city`, address.city);
		this.props.change(`${this.addressKey}_state`, address.state);
		this.props.change(`${this.addressKey}_zip`, address.zip);
	}

	findAddressComponent(components, type, version) {
		// this address throws error: 5675 Broadway, Bronx, NY, USA
		try {
			const address = components.find(function (component) {
				return component.types.indexOf(type) !== -1;
			});

			return address[version];
		} catch (error) {
			if (type === "locality") {
				return this.findAddressComponent(components, "sublocality", version);
			}
			if (
				type === "administrative_area_level_2" &&
				this.addressType === "Business"
			) {
				return ""; // need to trigger requried validation for country on biz addresses
			}
			return "";
		}
	}

	toggleManualAddress() {
		this.setState({
			manualAddress: !this.state.manualAddress,
		});
	}

	existingAddress() {
		if (
			this.props.address.street.length > 0 &&
			this.props.address.city.length > 0 &&
			this.props.address.state.length > 0
		) {
			return true;
		} else {
			return false;
		}
	}

	clearSearch() {
		//reset addressFound
		this.setState({ addressFound: false });

		// clear manual address fields
		this.props.change(`${this.addressKey}_street`, "");
		this.props.change(`${this.addressKey}_suite`, "");
		this.props.change(`${this.addressKey}_city`, "");
		this.props.change(`${this.addressKey}_state`, "");
		this.props.change(`${this.addressKey}_zip`, "");

		// clear geosuggest box and then focus it
		this._geoSuggest.clear();
		this._geoSuggest.focus();
	}

	customSuggestItem(suggest) {
		let address = suggest.label.split(",");

		return (
			<CustomItem street={address[0]} city={address[1]} state={address[2]} />
		);
	}

	onFocus() {
		if (this._geoSuggest) {
			this._geoSuggest.scrollIntoView(true);
		}
	}

	render() {
		const showAddressLookup = this.state.manualAddress ? "hide" : "show";
		const forceShow = this.state.addressFound ? "force-show" : "";

		return (
			<div className="address">
				<label className="label-above">Look up address or enter manually</label>
				<div className={`searchInput ${showAddressLookup}`}>
					<div className="chevron-icon">
						<ChevronIcon fill={this.props.theme.primary} />
					</div>

					<Geosuggest
						ref={(el) => (this._geoSuggest = el)}
						value=""
						placeholder={`Lookup ${this.addressType} Address`}
						onSuggestSelect={this.onSuggestSelect}
						readOnly={this.state.manualAddress}
						className={showAddressLookup}
						country="us"
						renderSuggestItem={this.customSuggestItem}
						autoFocus
					/>

					<div
						className={`close-icon ${forceShow}`}
						onClick={() => this.clearSearch()}
					>
						<CloseIcon fill={this.props.theme.primary} width="22" height="22" />
					</div>

					<div className="search-icon" onClick={() => this.clearSearch()}>
						<SearchIcon fill={this.props.theme.primary} />
					</div>
				</div>

				{/* <AddressFound showAddress={showAddressFound} address={this.state.address} /> */}

				<AddressManual
					address={this.state.address}
					addressKey={this.addressKey}
					addressType={this.addressType}
					manualAddress={this.state.manualAddress}
					toggleManualAddress={this.toggleManualAddress}
				/>
			</div>
		);
	}
}

export default Address;
